import React from 'react'

function Logs({ logs }) {
    return (
        <div id=":r1:">
            <div class="react-scroll-to-bottom--css-dyjff-79elbk h-60 overflow-auto bg-r8-gray-3 text-r8-gray-12 text-sm font-mono">
                <div class="react-scroll-to-bottom--css-dyjff-1n7m0yu">
                    <div class="p-05lh whitespace-pre" role="log" aria-label="Logs">
                        {logs}
                    </div>
                </div>
                <button class="react-scroll-to-bottom--css-dyjff-1tj0vk3" type="button"></button>
            </div>
        </div>
    )
}

export default Logs