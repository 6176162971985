import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Loading from './Loading'
import Logs from './Logs'
import { generateOutput, getCookie } from '../utils/methods'
import ImageOuput from './ImageOuput'
import TextOutput from './TextOutput'
import VideoOutput from './VideoOutput'
import { getModelOutput } from '../services/model-generation'
import Alert from './Alert'

const component = {
  img: ImageOuput,
  text: TextOutput,
  video: VideoOutput
}
function ModelOutput2({ output, outputType, model_type, service_name, loading, setLoading, getUserCredits }) {
  const [NewComponent, setNewComponent] = useState(null)
  const [logs, setLogs] = useState("")
  const [showLogs, setShowLogs] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const getOutput = async (url) => {
    setLoading(true);
    let interval;
    interval = setInterval(async () => {
      const data = await getModelOutput({
        path: url,
        service_name
      })
      if (data && (data.data.status == "success") && (data.data.status == "succeeded")) {
        clearInterval(interval)
      }
      console.log("getModelOutput", data)
      if (data && data.error) {
        clearInterval(interval)
        setLoading(false);
        setErrorMsg(data.error)
        return;
      } else {
        setErrorMsg("")
      }
      const newData = generateOutput(data, service_name, outputType, model_type);
      if (newData && (newData.outputType == "error")) {
        clearInterval(interval)
        setLoading(false);
        setErrorMsg(newData.value?newData.value[0]:"Something went wrong")
        return
      }
      if (!newData) {
        clearInterval(interval)
        setLoading(false);
        setNewComponent(null)
      }
      if (newData && !(newData.outputType == "refetch")) {
        clearInterval(interval)
        setLoading(false);
        getUserCredits()
        setNewComponent({ Output: component[newData.outputType], value: newData.value })
      }
      if (output && (output.outputType == "refetch")) {
        setLogs(data.data.logs)
        setShowLogs(true)
      }
    }, 1000);
    // return res.data.data
  }


  useEffect(() => {
    if (!output) {
      setNewComponent(null)
    }
    if (output && !(output.outputType == "error") && !(output.outputType == "failed") && !(output.outputType == "refetch")) {
      getUserCredits()
      setNewComponent({ Output: component[output.outputType], value: output.value })
    }
    if (output && (output.outputType == "refetch")) {
      getOutput(output.value)
    }
    if (output && (output.outputType == "error")) {
      setErrorMsg(output.value?output.value[0]:"Something went wrong")
    }
  }, [output])

  return (
    <div className="flex-1 min-w-0 scroll-mt-20">
      {
        errorMsg &&
        <Alert message={errorMsg} />
      }
      <h4 className="flex-1 mb-lh">Output</h4>
      <div className="mb-lh">
        <output className="mb-lh" data-prediction-output="true">
          <figure>
            {
              loading ?
                <Loading />
                :
                (
                  NewComponent ?
                    <NewComponent.Output output={NewComponent.value} />
                    : "No Ouput"
                )
            }
            <figcaption className="pt-4 text-shade">
              Generated in <time dateTime="PT1.89S">1.89</time> seconds
            </figcaption>
          </figure>
        </output>
      </div>
      <div className="inline-block mr-3 mb-3">
        <a
          className="form-button-secondary"
          href="#"
        >
          <svg
            className="icon"
            fill="none"
            height="24"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15 3h4a2 2 0 012 2v14a2 2 0 01-2 2h-4" />
            <polyline points="10 17 15 12 10 7" />
            <line x1="15" x2="3" y1="12" y2="12" />
          </svg>{" "}
          Share
        </a>
      </div>
      <div className="inline-block mr-3 mb-3">
        <button className="form-button-secondary">
          <svg
            className="icon"
            fill="none"
            height="24"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
            <polyline points="7 10 12 15 17 10" />
            <line x1="12" x2="12" y1="15" y2="3" />
          </svg>{" "}
          Download
        </button>
      </div>
      <div className="inline-block mr-3 mb-3">
        <a
          className="form-button-secondary mb-lh"
          href="#"
        >
          <svg
            className="icon"
            fill="none"
            height="24"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
            <line x1="12" x2="12" y1="8" y2="12" />
            <line x1="12" x2="12.01" y1="16" y2="16" />
          </svg>{" "}
          Report
        </a>
      </div>
      <div className="mb-lh text-xs hover:underline cursor-pointer" onClick={() => setShowLogs(!showLogs)}>
        <svg
          className="icon"
          fill="none"
          height="24"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polyline points="4 17 10 11 4 5" />
          <line x1="12" x2="20" y1="19" y2="19" />
        </svg>{" "}
        Show logs
      </div>
      {
        showLogs &&
        <Logs logs={logs} />
      }
    </div>
  )
}

export default ModelOutput2