import './App.css';
import ModelGeneration from './pages/model-generation';
import Header from './components/Header';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { useEffect, useState } from 'react';
import Login from './pages/login';
import Register from './pages/register';
import { getCookie } from './utils/methods';
import Collection from './pages/collection';
import Model from './pages/model';
import ChangePassword from './pages/change-password';
import { getCredits } from './services/auth';
import NotFound from './pages/404';
import Upgrade from './components/Upgrade';
import Alert from './components/Alert';

function App() {
  const [isAuth, setIsAuth] = useState(getCookie('token') ? true : false);
  const [userCredits, setUserCredits] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")

  const getUserCredits = async () => {
    const response = await getCredits()
    if (response && response.error) {
      setErrorMessage(response.error)
      return;
    } else {
      setErrorMessage("")
    }
    if (response.data) {
      console.log("cre response.data", response.data)
      setUserCredits(response.data)
    }
  }
  useEffect(() => {
    if (isAuth) {
      getUserCredits();
    }
  }, [isAuth])
  useEffect(() => {
    console.log("getCookie('token')", getCookie('token'))
    if (getCookie('token')) {
      setIsAuth(true)
    }
  }, [])

  return (
    <div className="App">
      {
        errorMessage ?
          <Alert message={errorMessage} />
          : null
      }
      <BrowserRouter>
        <Header isAuth={isAuth} userCredits={userCredits} setIsAuth={setIsAuth} />
        <Routes>
          <Route path='/login' element={<Login setAuth={setIsAuth} />} />
          <Route path='/change-password/:reset_token?' element={<ChangePassword />} />
          <Route path='/register' element={<Register />} />
          {/* <Route path='/:input_type/:service_name/:model_type?' element={<ModelGeneration />} /> */}
          <Route path='/collection' element={<ProtectedRoute isAuth={isAuth} userCredits={userCredits} />}>
            <Route path='/collection/:collection' element={<Model />} />
          </Route>
          <Route path='/' element={<ProtectedRoute isAuth={isAuth} userCredits={userCredits} />}>
            <Route path='/:input_type/:service_name/:model_type?' element={<ModelGeneration getUserCredits={getUserCredits} />} />
          </Route>
          <Route path='/' element={<ProtectedRoute isAuth={isAuth} userCredits={userCredits} />}>
            <Route path='/collections' element={<Collection />} />
          </Route>
          <Route path='/' element={<ProtectedRoute isAuth={isAuth} userCredits={userCredits} />}>
            <Route path='/payment' element={<Upgrade message="Add Credits" />} />
          </Route>
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
