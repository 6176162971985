import React from 'react'

function ImageOuput({output}) {
    return (
        <div>
            <div>
                <a
                    // href={result}
                    rel="noreferrer"
                    target="_blank"
                >
                    <img
                        alt="output"
                        className="lazy"
                        src={output}
                    />
                </a>
            </div>
        </div>
    )
}

export default ImageOuput