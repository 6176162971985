export const models = {
    text2text: {
        title: "Text-to-Text",
        models: [
            {
                service: "chatgpt",
                image: require("../assets/Images/models/model-1.png"),
                description: "Most capable GPT-3.5 model and optimized for chat at 1/10th the cost of text-davinci-003. Will be updated with our latest model iteration 2 weeks after it is released."
            },
            {
                service: "chatgpt/gpt4",
                image: require("../assets/Images/models/model-2.png"),
                description: "More capable than any GPT-3.5 model, able to do more complex tasks, and optimized for chat. Will be updated with our latest model iteration 2 weeks after it is released."
            },
            {
                service: "googlepalm",
                image: require("../assets/Images/models/model-3.png"),
                description: "The PaLM API is based on Google’s next generation model, PaLM 2, which excels at a variety of capabilities. PaLM 2 has been optimized for ease of use on key developer use cases and the ability to follow instructions with precision and nuance."
            },
        ]
    },
    text2video: {
        title: "Text-to-Video",
        models: [
            {
                service: "replicate",
                image: require("../assets/Images/models/model-4.gif"),
                description: "Text-to-video generation"
            },
            {
                service: "stable_fusion",
                image: require("../assets/Images/models/model-4.gif"),
                description: "Text-to-video generation"
            }
        ]
    },
    text2img: {
        title: "Text-to-Image",
        models: [
            {
                service: "chatgpt",
                image: require("../assets/Images/models/model-5.png"),
                description: "Text-to-image generation"
            },
            {
                service: "stable_fusion",
                image: require("../assets/Images/models/model-6.png"),
                description: "A latent text-to-image diffusion model capable of generating photo-realistic images given any text input"
            },
        ]
    },
    text_to_3d: {
        title: "Text-to-3D",
        models: [
            {
                service: "stable_fusion",
                image: require("../assets/Images/models/model-7.gif"),
                description: "generates 3D image from text prompt"
            },
        ]
    },
    img2img: {
        title: "Image-to-Image",
        models: [
            {
                service: "stable_fusion",
                image: require("../assets/Images/models/model-8.png"),
                description: "generates and returns an image from an image passed"
            }
        ]
    },
    image2text: {
        title: "Image-to-Text",
        models: [
            {
                service: "replicate",
                image: require("../assets/Images/models/model-9.jpg"),
                description: "Get an approximate text prompt, with style, matching an image. (Optimized for stable-diffusion (clip ViT-L/14))"
            }
        ]
    },
    img_to_3d: {
        title: "Image-to-3D",
        models: [
            {
                service: "stable_fusion",
                image: require("../assets/Images/models/model-10.png"),
                description: "generates 3D image from image"
            }
        ]
    },
    speech2text: {
        title: "Speech-To-Text",
        models: [
            {
                service: "chatgpt",
                image: require("../assets/Images/models/model-11.png"),
                description: "converts speech to text"
            }
        ]
    },
    video2video: {
        title: "Video-to-Video",
        models: [
            {
                service: "replicate",
                image: require("../assets/Images/models/model-12.gif"),
                description: "About Tune-A-Video: One-Shot Tuning of Image Diffusion Models for Text-to-Video Generation"
            },
            {
                service: "replicate/foreground",
                image: require("../assets/Images/models/model-13.gif"),
                description: "extract foreground of a video"
            },
        ]
    },
    frames_to_video: {
        title: "Frames-to-video",
        models: [
            {
                service: "replicate",
                image: require("../assets/Images/models/model-11.png"),
                description: "converts frames to video"
            }
        ]
    },
}