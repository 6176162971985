import React from "react";
import { deleteCookie } from "../utils/methods";
import { useNavigate } from "react-router-dom";

const Header = ({isAuth, userCredits, setIsAuth}) => {
  const navigate = useNavigate();
  

  return (
    <div id="header" className="md:sticky pt-5 px-6 pb-2 mb-6 -top-4 md:border-b z-10 bg-white">
      <header className="xl:mr-16 xl:pr-2 flex flex-wrap items-center ">
        <h1 className="flex flex-nowrap mr-6 md:text-4xl">
          <a
            href=""
            title="Replicate"
            className="no-focus no-outline focus:text-white"
          >
           <img className="m-2 block align-middle" alt="logo" src={require('../assets/Images/logo.jpg')}/>
          </a>
        </h1>

        <div className="hidden md:block text-right flex-grow">
          <nav className="flex justify-end items-start">
            <div className="glass flex flex-wrap justify-end" style={{gap: "10px"}}>
              <a href="#" className=" hover:text-black px-3.5 inline-block align-top leading-10 text-[#00000073]" onClick={e=>{
                e.preventDefault();
                navigate("/collections")
              }}>
                Explore
              </a>
              <a href="#" className="px-3.5 align-top leading-10 text-white bg-black hover:bg-[#00000073]" onClick={e=>{
                e.preventDefault();
                if (isAuth) {
                  deleteCookie('token')
                  setIsAuth(false)
                }
                navigate("/login")
              }}>
                {isAuth?"Sign out": "Sign in"}
              </a>
              {
                isAuth?
                <a href="#" className="px-3.5 align-top leading-10 text-white bg-black hover:bg-[#00000073]" onClick={e=>{
                  e.preventDefault();
                  navigate("/payment")
                }}>
                  Add Credits
                </a>
                :null
              }
            </div>
          </nav>
        </div>
      </header>
      {
        isAuth?
        <p style={{textAlign: "center"}}> <span style={{fontWeight: "bold"}}>CREDITS AVAILABLE:</span> {userCredits}</p>
        :null
      }

      <div className="block md:hidden flex-grow -mx-6 pt-6 md:pb-6 md:mt-12 mb-6">
        <nav className="flex justify-end items-start">
          <div className="glass flex flex-wrap justify-end">
            <a href="/" className="nav-link selected" onClick={e=>{
                e.preventDefault();
                navigate("/collections")
              }}>
              Explore
            </a>

            <a href="" className="nav-link-primary" onClick={e=>{
              e.preventDefault();
              if (isAuth) {
                deleteCookie('token')
                setIsAuth(false)
              }
              navigate("/login")
            }}>
              {isAuth?"Sign out": "Sign in"}
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;