import React from 'react'
import CollectionCard from '../components/CollectionCard'
import { collections } from './collections'

function Collection() {
    return (
        <main className="xl:mx-24 xl:px-2 lg:mx-14 mx-8 flex-1 md:scroll-mt-20 ">
            <section
                id="collections"
                className="mb-12 pb-12 border-b  border-black/10"
            >
                <h3 className="mb-6 m-0 text-xxl">Collections</h3>
                <div className="grid grid-cols-1 xl:grid-cols-2 grid-flow-row auto-rows-max gap-12">
                    {
                        collections.map(({ title, description, type, image }) => {
                            return (
                                <CollectionCard title={title} description={description} type={type} image={image} />
                            )
                        })
                    }
                </div>
            </section>
        </main>
    )
}

export default Collection