import { chatgpt } from "./fields/chatgpt";
import { googlepalm } from "./fields/googlepalm";
import { replicate } from "./fields/replicate";
import { stable_fusion } from "./fields/stable_fusion";

export const servicesObj = {
    chatgpt,
    googlepalm,
    stable_fusion,
    replicate,
}