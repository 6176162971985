import TextArea from '../../components/common/TextArea'
import Select from '../../components/common/Select'
import NumberWithRange from '../../components/common/NumberWithRange';
import NumberInput from '../../components/common/NumberInput';

export const googlepalm = {
    text2text: {
        type1: [
        {
            component: TextArea,
            props: {
                label: "prompt",
                defaultValue: "These practices, also known as ‘dark patterns,’ are in the nature of unfair trade practices and are covered under the Consumer Protection Act, which is punishable, according to Union consumer affairs secretary Rohit Kumar Singh.",
                name: "prompt",
                description: "Prompt to send to FLAN-T5.",
                style: {
                    height: "122px !important",
                }
            }
        },
        // {
        //     component: NumberInput,
        //     props: {
        //         label: "max_tokens",
        //         defaultValue: "16",
        //         min: "0",
        //         max: "30",
        //         id: "input-max_tokens",
        //         name: "max_tokens",
        //         step: "1",
        //         description: "Random seed. Leave blank to randomize the seed",
        //     }
        // },
        // {
        //     component: NumberWithRange,
        //     props: {
        //         label: "temperature",
        //         defaultValue: "0.75",
        //         min: "0.01",
        //         max: "2",
        //         id: "input-temperature",
        //         name: "temperature",
        //         step: "0.01",
        //         description: "Adjusts randomness of outputs, greater than 1 is random and 0 is deterministic, 0.75 is a good starting value. (minimum: 0.01; maximum: 5)",
        //     }
        // },
        // {
        //     component: NumberWithRange,
        //     props: {
        //         label: "top_p",
        //         defaultValue: "1",
        //         min: "0.01",
        //         max: "1",
        //         name: "top_p",
        //         step: "0.01",
        //         description: "When decoding text, samples from the top p percentage of most likely tokens; lower to ignore less likely tokens (minimum: 0.01; maximum: 1)",
        //     }
        // },
        // {
        //     component: Select,
        //     props: {
        //         id: "input-echo",
        //         label: "echo",
        //         name: "echo",
        //         description: "Echo back the prompt in addition to the completion",
        //         options: [
        //             { label: "True", value: true },
        //             { label: "False", value: false },
        //         ],
        //     }
        // }
    ]},
}