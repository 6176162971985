import TextArea from '../../components/common/TextArea'
import Select from '../../components/common/Select'
import NumberInput from '../../components/common/NumberInput';
import VideoUpload from '../../components/common/VideoUpload';
import ImageUpload from '../../components/common/ImageUpload';
import ZipUpload from '../../components/common/ZipUpload';

const getHeightOptions = () => {
    const opts = [{
        label: "",
        value: "",
    }];
    for (let i = 1; i < 17; i++) {
        opts[i] = {
            label: i * 64,
            value: i * 64,
        }
    }
    return opts
}

export const replicate = {
    text2video: {
        type1: [
            {
                component: TextArea,
                props: {
                    label: "prompt",
                    defaultValue: "A path going into the woods",
                    name: "prompt",
                    description: "Prompt",
                    style: {
                        height: "50px !important",
                    }
                }
            },
            {
                component: TextArea,
                props: {
                    label: "negative_prompt",
                    name: "negative_prompt",
                    description: "Items you don't want in the video.",
                    style: {
                        height: "50px !important",
                    }
                }
            },
            {
                component: Select,
                props: {
                    id: "input-scheduler",
                    label: "scheduler",
                    name: "scheduler",
                    description: "Use it to set a scheduler for video creation.",
                    options: [{
                        label: "DDPMScheduler",
                        value: "DDPMScheduler"
                    },
                    {
                        label: "DDIMScheduler",
                        value: "DDIMScheduler"
                    },
                    {
                        label: "PNDMScheduler",
                        value: "PNDMScheduler"
                    },
                    {
                        label: "LMSDiscreteScheduler",
                        value: "LMSDiscreteScheduler"
                    },
                    {
                        label: "EulerDiscreteScheduler",
                        value: "EulerDiscreteScheduler"
                    },
                    {
                        label: "EulerAncestralDiscreteScheduler",
                        value: "EulerAncestralDiscreteScheduler"
                    },
                    {
                        label: "DPMSolverMultistepScheduler",
                        value: "DPMSolverMultistepScheduler"
                    },
                    {
                        label: "HeunDiscreteScheduler",
                        value: "HeunDiscreteScheduler"
                    },
                    {
                        label: "KDPM2DiscreteScheduler",
                        value: "KDPM2DiscreteScheduler"
                    },
                    {
                        label: "DPMSolverSinglestepScheduler",
                        value: "DPMSolverSinglestepScheduler"
                    },
                    {
                        label: "KDPM2AncestralDiscreteScheduler",
                        value: "KDPM2AncestralDiscreteScheduler"
                    },
                    {
                        label: "UniPCMultistepScheduler",
                        value: "UniPCMultistepScheduler"
                    },
                    {
                        label: "DDIMInverseScheduler",
                        value: "DDIMInverseScheduler"
                    },
                    {
                        label: "DEISMultistepScheduler",
                        value: "DEISMultistepScheduler"
                    },
                    {
                        label: "IPNDMScheduler",
                        value: "IPNDMScheduler"
                    },
                    {
                        label: "KarrasVeScheduler",
                        value: "KarrasVeScheduler"
                    },
                    {
                        label: "ScoreSdeVeScheduler",
                        value: "ScoreSdeVeScheduler"
                    }],
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "seconds",
                    id: "input-seconds",
                    name: "seconds",
                    step: "1",
                    min: "0",
                    max: "60",
                    description: "Number of iterations of pasting the image in it's center and inpainting the boarders",
                }
            },
        ]
    }
    ,
    image2text: {
        type1: [
            {
                component: ImageUpload,
                props: {
                    label: "image",
                    id: "input-image",
                    name: "image",
                    description: "The main input image - Style image in style-transfer.",
                    isImageInput: true
                }
            },
        ]
    },
    video2video: {
        type1: [
            {
                component: VideoUpload,
                props: {
                    label: "video",
                    id: "input-video",
                    name: "video",
                    isImageInput: true,
                    isVideo: true
                }
            },
            {
                component: TextArea,
                props: {
                    label: "source_prompt",
                    defaultValue: "a man surfing",
                    name: "source_prompt",
                    description: "prompts describing the original video",
                    style: {
                        height: "50px !important",
                    }
                }
            },
            {
                component: TextArea,
                props: {
                    label: "target_prompts",
                    defaultValue: "a panda surfing \na cartoon sloth surfing",
                    name: "target_prompts",
                    description: "prompts to change the video to",
                    style: {
                        height: "50px !important",
                    }
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "steps",
                    defaultValue: "300",
                    id: "input-steps",
                    name: "steps",
                    step: "1",
                    description: "number of steps to train for",
                }
            },
            {
                component: Select,
                props: {
                    id: "input-width",
                    label: "width",
                    defaultValue: "512",
                    name: "width",
                    description: "Width of generated image in pixels. Needs to be a multiple of 64",
                    options: getHeightOptions(),
                }
            },
            {
                component: Select,
                props: {
                    id: "input-height",
                    label: "height",
                    defaultValue: "512",
                    name: "height",
                    description: "Width of generated image in pixels. Needs to be a multiple of 64",
                    options: getHeightOptions(),
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "length",
                    defaultValue: "5",
                    id: "input-length",
                    name: "length",
                    step: "1",
                    description: "length of the output video (in seconds)",
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "sample_frame_rate",
                    defaultValue: "1",
                    id: "input-sample_frame_rate",
                    name: "sample_frame_rate",
                    step: "1",
                    description: "with which rate to sample the input video",
                }
            },
        ],
        foreground: [
            {
                component: VideoUpload,
                props: {
                    label: "input_video",
                    id: "input-input_video",
                    name: "input_video",
                    isImageInput: true,
                    isVideo: true
                }
            },
            {
                component: Select,
                props: {
                    id: "input-output_type",
                    label: "output_type",
                    name: "output_type",
                    description: "An enumeration.",
                    options: [{
                        label: "green-screen",
                        value: "green-screen",
                    }, {
                        label: "alpha-mask",
                        value: "alpha-mask",
                    }, {
                        label: "foreground-mask",
                        value: "foreground-mask",
                    }],
                }
            }
        ]
    },
    frames_to_video: {
        type1: [
            {
                component: ZipUpload,
                props: {
                    label: "frames_zip",
                    id: "input-frames_zip",
                    name: "frames_zip",
                    description: "ZIP file containing frames",
                    isImageInput: true
                }
            },
            {
                component: TextArea,
                props: {
                    label: "frames_urls",
                    name: "frames_urls",
                    description: "Newline-separated URLs of frames to combine into a video",
                    style: {
                        height: "30px !important",
                    }
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "fps",
                    defaultValue: "1",
                    id: "input-fps",
                    name: "fps",
                    step: "1",
                    min: "1",
                    description: "Number of frames per second of video",
                }
            },
        ]
    },
}