import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Upgrade from './components/Upgrade';

const ProtectedRoute = ({isAuth, userCredits}) => {
    const [isSubscribed, setIsSubscribed] = useState(false)
    useEffect(() => {
        if (userCredits>0) {
            setIsSubscribed(true)
        } else {
            setIsSubscribed(false)
        }
    }, [userCredits])
    console.log("isSubscribed", isSubscribed, userCredits)
    return isAuth ? (isSubscribed?<Outlet />:<Upgrade />) : <Navigate to="/login" />;
}

export default ProtectedRoute;