import React, { useEffect, useState } from 'react'
import Button from './common/Button';
import { servicesObj } from '../utils/servicesObj';
import { generateModel, uploadFile } from '../services/model-generation';
import Alert from './Alert';

function ModelInput({ input_type, service_name, model_type, setOutput, setLoading }) {
  const [values, setValues] = useState(null)
  const [previews, setPreviews] = useState(null)
  const [errorMsg, setErrorMsg] = useState("")
  console.log("values", values)
  const handleChange = (e, name) => {
    setValues({ ...values, [name]: e.target.value })
  }

  const handleImageUpload = async (e, name) => {
    const response = await uploadFile(e.target.files[0])
    console.log("response image", response)
    const preview = response.data.url
    setPreviews({ ...previews, [name]: preview })
    setValues({ ...values, [name]: response.data.url })
  }

  useEffect(() => {
    if (servicesObj[service_name] && servicesObj[service_name][input_type]) {
      servicesObj[service_name][input_type][model_type].forEach(({ component: Component, props }) => {
        if (props.defaultValue) {
          // console.log("setValues", props.name, props.defaultValue)
          setValues((prevVals)=>({
            ...prevVals,
            [props.name]: props.defaultValue
          }))
        }
      })
    }
  }, [service_name])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isFormData = previews ? true : false;
    setOutput(null)
    setLoading(true)
    const response = await generateModel(input_type, service_name, model_type, values, isFormData);
    setLoading(false)
    if (response && response.error) {
      setErrorMsg(response.error)
      return;
    } else {
      setErrorMsg("")
    }
    console.log("setOutput(response)", response)
    setOutput(response)
  }
  return (
    <div className="flex-1 min-w-0">
      {
      errorMsg &&
      <Alert message={errorMsg} />
      }
      <h4 className="flex-1 mb-lh">Input</h4>
      <form action="#" noValidate 
      onSubmit={handleSubmit}>
        {
          servicesObj[service_name] && servicesObj[service_name][input_type] ?
            servicesObj[service_name][input_type][model_type].map(({ component: Component, props }) => {
              return <Component {...props} previews={previews} onChange={e => (props.isImageInput || props.isAudioInput) ? handleImageUpload(e, props.name) : handleChange(e, props.name)} />
            }) : "404 Not Found"
        }
        <Button classes="mr-2" type="submit">Submit</Button>
        <Button outlined type="button">Reset</Button>
      </form>
    </div>
  )
}

export default ModelInput