import React from 'react'

function Select({label, options, description, ...inputProps}) {
  return (
    <div className="mb-lh">
          <label className="block mb-2" htmlFor="input-height">
            <svg
              className="inline-block mr-2 w-3 h-3"
              fill="none"
              height="24"
              role="presentation"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="8" x2="21" y1="6" y2="6" />
              <line x1="8" x2="21" y1="12" y2="12" />
              <line x1="8" x2="21" y1="18" y2="18" />
              <line x1="3" x2="3.01" y1="6" y2="6" />
              <line x1="3" x2="3.01" y1="12" y2="12" />
              <line x1="3" x2="3.01" y1="18" y2="18" />
            </svg>
            <code>{label}</code>
          </label>
          <select
            className="form-select w-full"
            {...inputProps}
          >
            {
                options && options.map(option=>{
                    return <option value={option.value}>{option.label}</option>
                })
            }
          </select>
          <p className="text-shade mt-1">
            {description}
          </p>
        </div>
  )
}

export default Select