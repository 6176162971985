import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../components/common/Input';
import { requestPasswordChange, resetPassword } from '../services/auth';
import Alert from '../components/Alert';

const ChangePassword = ({ setAuth }) => {
    const navigate = useNavigate();
    const { reset_token } = useParams();
    const [credentials, setCredentials] = useState({})
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const handleChange = e => {
        setCredentials(prevCreds => {
            prevCreds[e.target.name] = e.target.value;
            return prevCreds;
        })
    }
    const handleSubmit = async () => {
        try {
            if (reset_token) {
                const response = await resetPassword({ ...credentials, resetToken: reset_token });
                if (response && response.error) {
                    setErrorMessage(response.error)
                    return;
                } else {
                    setErrorMessage("")
                }
                if (response.success) {
                    setSuccessMessage(response.message)
                    setTimeout(() => {
                        setSuccessMessage("")
                        navigate("/login")
                    }, 3000);
                } else {
                    setSuccessMessage("")
                }
                return;
            }
            const response = await requestPasswordChange(credentials);
            if (response && response.error) {
                setErrorMessage(response.error)
                return;
            } else {
                setErrorMessage("")
            }
            console.log("register", response.token)
            if (response.success) {
                setSuccessMessage(response.message)
                setTimeout(() => {
                    setSuccessMessage("")
                }, 3000);
            } else {
                setSuccessMessage("")
            }
        } catch (error) {
            console.log("error", error.response.data.message)
        }
    }
    return (
        <div className='flex items-center justify-center bg-black w-full h-screen'>
            {
                successMessage ?
                    <Alert success={true} message={successMessage} />
                    : null
            }
            {
                errorMessage ?
                    <Alert message={errorMessage} />
                    : null
            }
            <div className='p-10 bg-white flex flex-col' style={{ width: "500px" }}>
                <h3 className='uppercase'>Change Password</h3>
                <Input name="email" label="email" type="text" placeholder='Enter Email' onChange={handleChange} />
                {
                    reset_token ?
                        <Input name="password" label="New Password" type="password" placeholder='Enter New Password' onChange={handleChange} />
                        : null
                }
                {/* <div className='flex flex-col py-3'>
                    <label htmlFor="" className='font-semibold text-slate-500'>Password</label>
                    <input type="password" placeholder='Enter Password' className='p-3 mt-2 border border-2 border-solid border-black' />
                </div> */}
                <div className="flex items-end justify-between my-4">
                    <button className='bg-black text-white px-8 h-11 uppercase' onClick={handleSubmit}>{reset_token ? "Reset Password" : "Get Password Reset Link"}</button>
                    {/* <div className='h-11 px-2 uppercase flex items-center border border-black justify-between '><img src={require("../Images/google.png")} alt="" width="40px" height="auto"/><span>Sign in with Google</span></div> */}

                </div>
                <a href="/#" className='text-slate-500 ml-auto' onClick={(e) => {
                    e.preventDefault();
                    navigate("/login")
                }}>Login</a>
            </div>
        </div>
    )
}

export default ChangePassword