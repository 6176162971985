import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Input from '../components/common/Input';
import { register } from '../services/auth';
import Alert from '../components/Alert';

const Register = () => {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({})
    const [cPassword, setCPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState("")

    const handleChange = e => {
        if (e.target.name == "c-password") {
            setCPassword(e.target.value)
        } else {
            setCredentials(prevCreds => {
                prevCreds[e.target.name] = e.target.value;
                return prevCreds;
            })
        }
    }
    const handleSubmit = async () => {
        try {
            const response = await register(credentials);
            if (response && response.error) {
                setErrorMsg(response.error)
                return;
              } else {
                setErrorMsg("")
              }
            if (response.success) {
                navigate("/login")
            }
        } catch (error) {
            console.log("error", error.response.data.message)
        }
    }
    return (
        <div className='flex items-center justify-center bg-black w-full h-screen'>
            {
                errorMsg &&
                <Alert message={errorMsg} />
            }
            <div className='p-10 bg-white' style={{ width: "500px" }}>
                <h3 className='uppercase'>Register</h3>
                <Input name="username" label="User Name" type="text" placeholder='Enter Login Id' onChange={handleChange} />
                <Input name="email" label="Email" type="email" placeholder='Enter Your Email' onChange={handleChange} />
                <Input name="password" label="Password" type="password" placeholder='Enter Password' onChange={handleChange} />
                <Input label="Confirm Password" type="password" placeholder='Confirm Password' onChange={handleChange} />

                {/* <div className='flex flex-col py-3'>
                    <label htmlFor="" className='font-semibold text-slate-500'>User Name</label>
                    <input type="text" placeholder='Enter Login Id' className='p-3 mt-2 border border-2 border-solid border-black' />
                </div> */}
                {/* <div className='flex flex-col py-3'>
                    <label htmlFor="" className='font-semibold text-slate-500'>Password</label>
                    <input type="password" placeholder='Enter Password' className='p-3 mt-2 border border-2 border-solid border-black' />
                </div> */}
                {/* <div className='flex flex-col py-3'>
                    <label htmlFor="" className='font-semibold text-slate-500'>Confirm Password</label>
                    <input type="password" placeholder='Confirm Password' className='p-3 mt-2 border border-2 border-solid border-black' />
                </div> */}
                <div className="flex items-end justify-between">
                    <button className='bg-black text-white px-8 py-2.5 uppercase mt-4' onClick={handleSubmit}>Register</button>
                    <div className='flex flex-col items-center' >
                        <p className='text-slate-500'>Already Have an Account?</p>
                        <a href="/#" onClick={(e) => {
                            e.preventDefault();
                            navigate("/login")
                        }}>Login Here</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register