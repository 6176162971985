import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { models } from './models';

function Model() {
  const {collection} = useParams();
  const navigate = useNavigate();
    return (
        <main className="xl:mx-24 xl:px-2 lg:mx-14 mx-8 flex-1 md:scroll-mt-20 ">
            <h2 class="mb-6 text-hh2">{models[collection].title}</h2>
            <p class="mb-12">Models that generate {models[collection].title}</p>
            <div class="my-6">
                <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 grid-flow-row auto-rows-max gap-12 ">
                    {
                        models[collection].models.map(model=>{
                            return (
                                <a
                                    class="no-underline flex flex-col "
                                    onClick={() => {
                                        navigate(`/${collection}/${model.service}`);
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div class="h-80 mb-2">
                                        <div class=" h-full w-full overflow-hidden">
                                            <img
                                                alt=""
                                                class="object-cover object-center w-full h-full"
                                                src={model.image}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {/* <div class="flex">
                                            <h4 class="flex-shrink overflow-hidden overflow-ellipsis text-h4">
                                                <span class="text-[#00000073]">arielreplicate</span>
                                                <span class="text-[#00000073] px-1">/</span>
                                                stable_diffusion_infinite_zoom
                                            </h4>
                                        </div> */}

                                        <p class="mb-1">
                                            {model.description}
                                        </p>
                                        {/* <div class="text-[#00000073] text-sm">
                                            <span class="float-right flex">
                                                <i className="icon">
                                                    <GoRocket />
                                                </i>
                                                25.8K runs
                                            </span>
                                        </div> */}
                                    </div>
                                </a>
                            )
                        })
                    }
                </div>
            </div>
        </main>
    )
}

export default Model