import React from 'react'

function Button({children, outlined, classes, ...buttonProps}) {
    return (
        <button className={`form-button${outlined?"-secondary":""} relative ${classes}`} {...buttonProps}>
            <span className="">{children}</span>
        </button>
    )
}

export default Button