import React from 'react'

function NumberWithRange({ label, description, ...inputProps }) {
    return (
        <div className="mb-lh">
            <label className="block mb-2" htmlFor="input-num_outputs">
                <svg
                    className="inline-block mr-2 w-3 h-3"
                    fill="none"
                    height="24"
                    role="presentation"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <line x1="4" x2="20" y1="9" y2="9" />
                    <line x1="4" x2="20" y1="15" y2="15" />
                    <line x1="10" x2="8" y1="3" y2="21" />
                    <line x1="16" x2="14" y1="3" y2="21" />
                </svg>
                <code>{label}</code>
            </label>
            <fieldset className="flex" id="input-num_outputs">
                <legend hidden>{label}</legend>
                <input
                    aria-label="num_outputs"
                    className="flex-none w-20 p-05lh mr-05lh border-shade border focus:outline-none focus:border-black"
                    {...inputProps}
                    type="number"
                // onChange={handleChange}
                />
                <input
                    aria-label="num_outputs"
                    className="flex-grow"
                    {...inputProps}
                    type="range"
                // onChange={handleChange}
                />
            </fieldset>
            <p className="text-shade mt-1">
                {description}
            </p>
        </div>
    )
}

export default NumberWithRange