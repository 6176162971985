import React, { useState } from 'react'
import ModelInput from '../components/ModelInput'
import ModelHeader from '../components/ModelHeader'
import { useParams } from 'react-router-dom'
import ModelOutput from '../components/ModelOutput';

function ModelGeneration({getUserCredits}) {
  const {input_type, service_name, model_type = "type1"} = useParams();
  const [output, setOutput] = useState(null)
  const [loading, setLoading] = useState(false)
  return (
    <>
      <main class="layout-main flex-1">
        <ModelHeader />
        <div className="mb-2lh">
          <div
            data-component="VersionRun"
            data-props="react-component-props-2132c5ee-46a6-4581-b954-fcc71f10af1b"
          >
            <div className="flex flex-col md:flex-row gap-2lh md:gap-lh">
            <ModelInput input_type={input_type} service_name={service_name} model_type={model_type} setOutput={setOutput} setLoading={setLoading} />
              <ModelOutput getUserCredits={getUserCredits} output={output} outputType={input_type} service_name={service_name} model_type={model_type} loading={loading} setLoading={setLoading} />
            </div>
          </div>
        </div>
        <div className="pb-2 border-b border-hairline mb-lh flex">
          <h4 className="inline-block flex-grow">Examples</h4>

          <p>
            <a
              className="no-default"
              href="/stability-ai/stable-diffusion/examples"
            >
              View more examples
              <span className="relative -top-0.5">
                <svg
                  className="icon"
                  fill="none"
                  height="24"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="7" x2="17" y1="17" y2="7" />

                  <polyline points="7 7 17 7 17 17" />
                </svg>
              </span>
            </a>
          </p>
        </div>
        <div className="mb-2lh h-40 overflow-hidden ">
          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#qffyxjvmbvfdbao7vvv2oss2gq">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy entered exited"
                src="https://replicate.delivery/mgxm/980bca24-f8d8-41bb-9f52-fe84bca9c251/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#rktab6ur7ve3dfuvh7efbo3j54">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy entered exited"
                src="https://replicate.delivery/mgxm/8d6a8069-b91f-4e61-8136-fa7c0775532c/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#bhyzpqdgnbhsljpnxp45hbv5ay">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy entered exited"
                src="https://replicate.delivery/mgxm/891fd1f9-302e-4d1f-a462-c0c2624ffbca/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#rjcl54wakbbrfcajzllmidreya">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy entered exited"
                src="https://replicate.delivery/mgxm/129d24dc-e39e-4240-8978-88420cc1f910/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#sqyie5bdmrewdoxi6ehhxlesfe">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy entered exited"
                src="https://replicate.delivery/mgxm/4ee3ab76-7864-4604-8897-9d330214d225/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#ljnv74ftfbfvtlqrvsz5zarakm">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy entered exited"
                src="https://replicate.delivery/mgxm/bb35c491-8794-43d6-81e1-41211831535d/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#kgpqs7h2ffcfrouxeoua6tpkjy">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy"
                src="https://replicate.com/api/models/stability-ai/stable-diffusion/files/0d5e6ce0-dd85-4dd8-a087-a9c59876b456/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#kmp3c6s2kjdqtboq22pyokrksi">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy"
                src="https://replicate.delivery/pbxt/AE5fg6Nbehm5fIkWbIVNsrK1jUEqRr8btVZwoQSEgMemLlpfB/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#rsytjp72cbghhh7gr4ax4rnwly">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy"
                src="https://replicate.delivery/pbxt/Uy5Ma1EruBKkOReB5TwrX9P1ekJjnSftkujvBqkDneSCMywfB/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#4irm2hlalvdmlkryu4df63pwnq">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy"
                src="https://replicate.delivery/pbxt/aooDunhAPcbUBh979fEblu4qqohEtlG0dPJUf1aLuUkMowCQA/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#uu3pugtcofbn3cgac6k4aymef4">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy"
                src="https://replicate.delivery/pbxt/2zVI7LtEO0aeKSruhERO0PQCVEsbFivhEyXyR0LDMSg3cYBIA/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#4hwtj7bbmvetvgdah5o4xln3qy">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy"
                src="https://replicate.delivery/pbxt/DogdQ8movv5LNlAaHGXrRU9eS1hZMs0OZpIthOsyTbeL6wCQA/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#cxpa7egscbbtjbsx4r6623v5n4">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy"
                src="https://replicate.delivery/pbxt/f4nlztv3uz1iFC4AEf2wBYQGTezdVeysvtZUtwfsvZOJDN6AC/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#3wztszjpcjbabi6iwgqukwyegq">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy"
                src="https://replicate.delivery/pbxt/XdWzrhW73EIAIxuxl92iE5qVHdiFMnNh3axdwp56EdSGUBCE/out-0.png"
                role="presentation"
              />
            </a>
          </div>

          <div className="inline-block h-40 w-40 overflow-hidden">
            <a href="/stability-ai/stable-diffusion/examples#uvb7ynit4bhpjds3vn4bx7npeq">
              <img
                alt=""
                className="object-cover object-center w-full h-full lazy"
                src="https://replicate.delivery/pbxt/sWeZFZou6v3CPKuoJbqX46ugPaHT1DcsWYx0srPmGrMOCPYIA/out-0.png"
                role="presentation"
              />
            </a>
          </div>
        </div>
        <div className="mb-2lh" id="performance">
          <h4 className="mb-lh pb-2 border-b border-hairline">
            Run time and cost
          </h4>

          <p className="mb-lh">
            Predictions run on Nvidia A100 (40GB) GPU hardware. Predictions
            typically complete within 6 seconds.
          </p>
        </div>
        <article>
          <div className="border-b border-hairline pb-2 mb-lh" id="readme">
            <h4 className="inline-block">
              <a className="no-default" href="#readme">
                Readme
              </a>
            </h4>
          </div>

          <div className="readme-prose">
            <p>
              Stable Diffusion is a latent text-to-image diffusion model capable
              of generating photo-realistic images given any text input.{" "}
            </p>

            <p>
              We’ve generated updated our fast version of Stable Diffusion to
              generate dynamically sized images up to 1024x1024. Here's links to
              the current version for 2.1 and 1.5:
            </p>

            <table>
              <thead>
                <tr>
                  <th>Stable Diffusion Version</th>
                  <th>Optimized for speed, flexible image sizes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2.1</td>
                  <td>
                    <a
                      href="https://replicate.com/stability-ai/stable-diffusion/versions/ac732df83cea7fff18b8472768c88ad041fa750ff7682a21affe81863cbe77e4"
                      rel="nofollow"
                    >
                      link
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>1.5</td>
                  <td>
                    <a
                      href="https://replicate.com/stability-ai/stable-diffusion/versions/b3d14e1cd1f9470bbb0bb68cac48e5f483e5be309551992cc33dc30654a82bb7"
                      rel="nofollow"
                    >
                      link
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              All previous versions of Stable Diffusion will continue to be on
              Replicate, see the version list for further details:{" "}
              <a
                href="https://replicate.com/stability-ai/stable-diffusion/versions"
                rel="nofollow"
              >
                https://replicate.com/stability-ai/stable-diffusion/versions
              </a>
            </p>

            <ul>
              <li>
                <strong>Developed by:</strong> Robin Rombach, Patrick Esser
              </li>

              <li>
                <strong>Model type:</strong> Diffusion-based text-to-image
                generation model
              </li>

              <li>
                <strong>Language(s):</strong> English
              </li>

              <li>
                <strong>License:</strong>{" "}
                <a
                  href="https://huggingface.co/spaces/CompVis/stable-diffusion-license"
                  rel="nofollow"
                >
                  The CreativeML OpenRAIL M license
                </a>{" "}
                is an{" "}
                <a
                  href="https://www.licenses.ai/blog/2022/8/18/naming-convention-of-responsible-ai-licenses"
                  rel="nofollow"
                >
                  Open RAIL M license
                </a>
                , adapted from the work that{" "}
                <a href="https://bigscience.huggingface.co/" rel="nofollow">
                  BigScience
                </a>{" "}
                and{" "}
                <a href="https://www.licenses.ai/" rel="nofollow">
                  the RAIL Initiative
                </a>{" "}
                are jointly carrying in the area of responsible AI licensing. See
                also{" "}
                <a
                  href="https://bigscience.huggingface.co/blog/the-bigscience-rail-license"
                  rel="nofollow"
                >
                  the article about the BLOOM Open RAIL license
                </a>{" "}
                on which our license is based.
              </li>

              <li>
                <strong>Model Description:</strong> This is a model that can be
                used to generate and modify images based on text prompts. It is a{" "}
                <a href="https://arxiv.org/abs/2112.10752" rel="nofollow">
                  Latent Diffusion Model
                </a>{" "}
                that uses a fixed, pretrained text encoder (
                <a href="https://arxiv.org/abs/2103.00020" rel="nofollow">
                  CLIP ViT-L/14
                </a>
                ) as suggested in the{" "}
                <a href="https://arxiv.org/abs/2205.11487" rel="nofollow">
                  Imagen paper
                </a>
                .
              </li>

              <li>
                <strong>Resources for more information:</strong>{" "}
                <a
                  href="https://github.com/CompVis/stable-diffusion"
                  rel="nofollow"
                >
                  GitHub Repository
                </a>
                ,{" "}
                <a href="https://arxiv.org/abs/2112.10752" rel="nofollow">
                  Paper
                </a>
                .
              </li>

              <li>
                <strong>Cite as:</strong>
              </li>
            </ul>

            <div>
              <div className="group relative">
                <pre className="code">
                  <code className="!p-0 hljs language-julia">
                    {"      "}
                    <span className="hljs-meta">@InProceedings</span>
                    {`{Rombach_2022_CVPR,
            author    = {Rombach, Robin and Blattmann, Andreas and Lorenz, Dominik and Esser, Patrick and Ommer, Bj`}
                    <span className="hljs-string">
                      {`"orn},
              title     = {High-Resolution Image Synthesis With Latent Diffusion Models},
              booktitle = {Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition (CVPR)},
              month     = {June},
              year      = {2022},
              pages     = {10684-10695}
              }`}
                    </span>
                  </code>
                </pre>
                <div className="absolute right-2 top-0 bottom-0 h-full opacity-0 group-hover:opacity-100 flex top-2 items-start">
                  <button className="p-2 bg-white border-gray-300 text-gray-800 hover:text-gray-500 focus:outline-none">
                    <svg
                      className="lucide lucide-copy"
                      fill="none"
                      height="20"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect height="13" rx="2" ry="2" width="13" x="9" y="9" />
                      <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <h1 id="uses">Uses</h1>

            <h2 id="direct-use">Direct Use</h2>

            <p>
              The model is intended for research purposes only. Possible research
              areas and tasks include
            </p>

            <ul>
              <li>
                Safe deployment of models which have the potential to generate
                harmful content.
              </li>

              <li>
                Probing and understanding the limitations and biases of generative
                models.
              </li>

              <li>
                Generation of artworks and use in design and other artistic
                processes.
              </li>

              <li>Applications in educational or creative tools.</li>

              <li>Research on generative models.</li>
            </ul>

            <p>Excluded uses are described below.</p>

            <h3 id="misuse-malicious-use-and-out-of-scope-use">
              Misuse, Malicious Use, and Out-of-Scope Use
            </h3>

            <p>
              <em>
                Note: This section is taken from the{" "}
                <a
                  href="https://huggingface.co/dalle-mini/dalle-mini"
                  rel="nofollow"
                >
                  DALLE-MINI model card
                </a>
                , but applies in the same way to Stable Diffusion v1
              </em>
              .
            </p>

            <p>
              The model should not be used to intentionally create or disseminate
              images that create hostile or alienating environments for people.
              This includes generating images that people would foreseeably find
              disturbing, distressing, or offensive; or content that propagates
              historical or current stereotypes.
            </p>

            <h4 id="out-of-scope-use">Out-of-Scope Use</h4>

            <p>
              The model was not trained to be factual or true representations of
              people or events, and therefore using the model to generate such
              content is out-of-scope for the abilities of this model.
            </p>

            <h4 id="misuse-and-malicious-use">Misuse and Malicious Use</h4>

            <p>
              Using the model to generate content that is cruel to individuals is
              a misuse of this model. This includes, but is not limited to:
            </p>

            <ul>
              <li>
                Generating demeaning, dehumanizing, or otherwise harmful
                representations of people or their environments, cultures,
                religions, etc.
              </li>

              <li>
                Intentionally promoting or propagating discriminatory content or
                harmful stereotypes.
              </li>

              <li>Impersonating individuals without their consent.</li>

              <li>
                Sexual content without consent of the people who might see it.
              </li>

              <li>Mis- and disinformation</li>

              <li>Representations of egregious violence and gore</li>

              <li>
                Sharing of copyrighted or licensed material in violation of its
                terms of use.
              </li>

              <li>
                Sharing content that is an alteration of copyrighted or licensed
                material in violation of its terms of use.
              </li>
            </ul>

            <h2 id="limitations-and-bias">Limitations and Bias</h2>

            <h3 id="limitations">Limitations</h3>

            <ul>
              <li>The model does not achieve perfect photorealism</li>

              <li>The model cannot render legible text</li>

              <li>
                The model does not perform well on more difficult tasks which
                involve compositionality, such as rendering an image corresponding
                to “A red cube on top of a blue sphere”
              </li>

              <li>Faces and people in general may not be generated properly.</li>

              <li>
                The model was trained mainly with English captions and will not
                work as well in other languages.
              </li>

              <li>The autoencoding part of the model is lossy</li>

              <li>
                The model was trained on a large-scale dataset
                <a href="https://laion.ai/blog/laion-5b/" rel="nofollow">
                  LAION-5B
                </a>
                which contains adult material and is not fit for product use
                without additional safety mechanisms and considerations.
              </li>

              <li>
                No additional measures were used to deduplicate the dataset. As a
                result, we observe some degree of memorization for images that are
                duplicated in the training data. The training data can be searched
                at
                <a
                  href="https://rom1504.github.io/clip-retrieval/"
                  rel="nofollow"
                >
                  https://rom1504.github.io/clip-retrieval/
                </a>{" "}
                to possibly assist in the detection of memorized images.
              </li>
            </ul>

            <h3 id="bias">Bias</h3>

            <p>
              While the capabilities of image generation models are impressive,
              they can also reinforce or exacerbate social biases. Stable
              Diffusion v1 was trained on subsets of
              <a href="https://laion.ai/blog/laion-5b/" rel="nofollow">
                LAION-2B(en)
              </a>
              , which consists of images that are primarily limited to English
              descriptions. Texts and images from communities and cultures that
              use other languages are likely to be insufficiently accounted for.
              This affects the overall output of the model, as white and western
              cultures are often set as the default. Further, the ability of the
              model to generate content with non-English prompts is significantly
              worse than with English-language prompts.
            </p>

            <h2 id="training">Training</h2>

            <p>
              <strong>Training Data</strong>
              The model developers used the following dataset for training the
              model:
            </p>

            <ul>
              <li>LAION-2B (en) and subsets thereof (see next section)</li>
            </ul>

            <p>
              <strong>Training Procedure</strong>
              Stable Diffusion v1 is a latent diffusion model which combines an
              autoencoder with a diffusion model that is trained in the latent
              space of the autoencoder. During training,
            </p>

            <ul>
              <li>
                Images are encoded through an encoder, which turns images into
                latent representations. The autoencoder uses a relative
                downsampling factor of 8 and maps images of shape H x W x 3 to
                latents of shape H/f x W/f x 4
              </li>

              <li>Text prompts are encoded through a ViT-L/14 text-encoder.</li>

              <li>
                The non-pooled output of the text encoder is fed into the UNet
                backbone of the latent diffusion model via cross-attention.
              </li>

              <li>
                The loss is a reconstruction objective between the noise that was
                added to the latent and the prediction made by the UNet.
              </li>
            </ul>

            <p>
              We currently provide three checkpoints, <code>sd-v1-1.ckpt</code>,{" "}
              <code>sd-v1-2.ckpt</code> and <code>sd-v1-3.ckpt</code>, which were
              trained as follows,
            </p>

            <ul>
              <li>
                <code>sd-v1-1.ckpt</code>: 237k steps at resolution{" "}
                <code>256x256</code> on{" "}
                <a
                  href="https://huggingface.co/datasets/laion/laion2B-en"
                  rel="nofollow"
                >
                  laion2B-en
                </a>
                . 194k steps at resolution
                <code>512x512</code> on{" "}
                <a
                  href="https://huggingface.co/datasets/laion/laion-high-resolution"
                  rel="nofollow"
                >
                  laion-high-resolution
                </a>{" "}
                (170M examples from LAION-5B with resolution{" "}
                <code>{`>= 1024x1024`}</code>
                ).
              </li>

              <li>
                <code>sd-v1-2.ckpt</code>: Resumed from <code>sd-v1-1.ckpt</code>.
                515k steps at resolution
                <code>512x512</code>
                on "laion-improved-aesthetics" (a subset of laion2B-en, filtered
                to images with an original size
                <code>{`>= 512x512`}</code>, estimated aesthetics score{" "}
                <code>{`> 5.0`}</code>, and an estimated watermark probability{" "}
                <code>{`< 0.5`}</code>. The watermark estimate is from the
                LAION-5B metadata, the aesthetics score is estimated using an{" "}
                <a
                  href="https://github.com/christophschuhmann/improved-aesthetic-predictor"
                  rel="nofollow"
                >
                  improved aesthetics estimator
                </a>
                ).
              </li>

              <li>
                <p>
                  <code>sd-v1-3.ckpt</code>: Resumed from{" "}
                  <code>sd-v1-2.ckpt</code>. 195k steps at resolution{" "}
                  <code>512x512</code> on "laion-improved-aesthetics" and 10\%
                  dropping of the text-conditioning to improve{" "}
                  <a href="https://arxiv.org/abs/2207.12598" rel="nofollow">
                    classifier-free guidance sampling
                  </a>
                  .
                </p>
              </li>

              <li>
                <p>
                  <strong>Hardware:</strong> 32 x 8 x A100 GPUs
                </p>
              </li>

              <li>
                <strong>Optimizer:</strong> AdamW
              </li>

              <li>
                <strong>Gradient Accumulations</strong>: 2
              </li>

              <li>
                <strong>Batch:</strong> 32 x 8 x 2 x 4 = 2048
              </li>

              <li>
                <strong>Learning rate:</strong> warmup to 0.0001 for 10,000 steps
                and then kept constant
              </li>
            </ul>

            <h2 id="evaluation-results">Evaluation Results</h2>

            <p>
              Evaluations with different classifier-free guidance scales (1.5,
              2.0, 3.0, 4.0, 5.0, 6.0, 7.0, 8.0) and 50 PLMS sampling steps show
              the relative improvements of the checkpoints:
            </p>

            <p>
              <img
                alt=""
                src="https://bucketeer-be99e627-94e7-4e5b-a292-54eeb40ac303.s3.amazonaws.com/public/markdownx/2f1c5964-a2f2-4640-961f-c71307748edf.jpeg"
              />
            </p>

            <p>
              Evaluated using 50 PLMS steps and 10000 random prompts from the
              COCO2017 validation set, evaluated at 512x512 resolution. Not
              optimized for FID scores.
            </p>

            <h2 id="environmental-impact">Environmental Impact</h2>

            <p>
              <strong>Stable Diffusion v1</strong>{" "}
              <strong>Estimated Emissions</strong>
              Based on that information, we estimate the following CO2 emissions
              using the
              <a href="https://mlco2.github.io/impact#compute" rel="nofollow">
                Machine Learning Impact calculator
              </a>{" "}
              presented in{" "}
              <a href="https://arxiv.org/abs/1910.09700" rel="nofollow">
                Lacoste et al. (2019)
              </a>
              . The hardware, runtime, cloud provider, and compute region were
              utilized to estimate the carbon impact.
            </p>

            <ul>
              <li>
                <strong>Hardware Type:</strong> A100 PCIe 40GB
              </li>

              <li>
                <strong>Hours used:</strong> 150000
              </li>

              <li>
                <strong>Cloud Provider:</strong> AWS
              </li>

              <li>
                <strong>Compute Region:</strong> US-east
              </li>

              <li>
                <strong>
                  Carbon Emitted (Power consumption x Time x Carbon produced based
                  on location of power grid):
                </strong>{" "}
                11250 kg CO2 eq.
              </li>
            </ul>

            <h2 id="citation">Citation</h2>

            <div>
              <div className="group relative">
                <pre className="code">
                  <code className="language-bibtex !p-0">
                    {`    @InProceedings{Rombach_2022_CVPR,
            author    = {Rombach, Robin and Blattmann, Andreas and Lorenz, Dominik and Esser, Patrick and Ommer, Bj\"orn},
            title     = {High-Resolution Image Synthesis With Latent Diffusion Models},
            booktitle = {Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition (CVPR)},
            month     = {June},
            year      = {2022},
            pages     = {10684-10695}
            }`}
                  </code>
                </pre>
                <div className="absolute right-2 top-0 bottom-0 h-full opacity-0 group-hover:opacity-100 flex top-2 items-start">
                  <button className="p-2 bg-white border-gray-300 text-gray-800 hover:text-gray-500 focus:outline-none">
                    <svg
                      className="lucide lucide-copy"
                      fill="none"
                      height="20"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect height="13" rx="2" ry="2" width="13" x="9" y="9" />
                      <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <p>
              <em>
                This model card was written by: Robin Rombach and Patrick Esser
                and is based on the{" "}
                <a
                  href="https://huggingface.co/dalle-mini/dalle-mini"
                  rel="nofollow"
                >
                  DALL-E Mini model card
                </a>
                .
              </em>
            </p>
          </div>
        </article>
      </main>
    </>
  )
}

export default ModelGeneration