import React from 'react'

function TextOutput({output}) {
    return (
        <div>
            <div>
                <pre className="overflow-x-auto overflow-y-auto whitespace-pre-wrap max-h-96">
                    <code className="output w-full">
                        {output}
                    </code>
                </pre>
            </div>
        </div>
    )
}

export default TextOutput