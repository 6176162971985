import React from 'react'

function TextArea({label, description, ...inputProps}) {
  return (
    <div className="mb-lh">
          <label className="block mb-2" htmlFor="input-prompt">
            <svg
              className="inline-block mr-2 w-3 h-3"
              fill="none"
              height="24"
              role="presentation"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="4 7 4 4 20 4 20 7" />
              <line x1="9" x2="15" y1="20" y2="20" />
              <line x1="12" x2="12" y1="4" y2="20" />
            </svg>
            <code>{label}</code>
          </label>
          <div>
            <textarea
              className="form-input w-full resize-none"
              {...inputProps}
            />
          </div>
          <p className="text-shade mt-1">{description}</p>
        </div>
  )
}

export default TextArea