export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function generateOutput(response, service, type, model) {
    switch (service) {
      case "chatgpt":
        if ((type == "text2text") && (model == "type1") || (type == "speech2text") && (model == "type1")) {
          if (response && response.data && response.data.output && response.data.output[0]) {
            return {outputType: "text", value: response.data.output[0].text}
          } else {
            return null;
          }
        }
        if ((type == "text2text") && (model == "gpt4")) {
          if (response && response.data && response.data.output && response.data.output[0]) {
            return {outputType: "text", value: response.data.output[0].message.content};
          } else {
            return null;
          }
        }
        if ((type == "text2img") && (model == "type1")) {
          if (response && response.data && response.data.output && response.data.output[0]) {
            return {outputType: "img", value: response.data.output[0].url};
          } {
            return null;
          }
        }
      case "stable_fusion":
        if (response && response.data) {
          if ((response.data.status == "failed")) {
            let errorArr=["Something went wrong"]
            if (response.data.messege) {
              errorArr = [response.data.messege]
            }
            return {outputType: "error", value: errorArr};
          }
          if ((response.data.status == "error")) {
            let errorArr=["Something went wrong"]
            if (response.data.messege) {
              errorArr = Object.keys(response.data.messege).map(errData=> response.data.messege[errData])
            } else if (response.data.message) {
              errorArr=[response.data.message]
            }
            console.log("errorArr", errorArr, response.data.messege)
            return {outputType: "error", value: errorArr};
          }
          if (response.data.status == "success") {
            if (((type == "text2img") && (model == "type1")) || (type == "img2img") && (model == "type1")) {
              if (response && response.data && response.data.output && response.data.output[0]) {
                return {outputType: "img", value: response.data.output[0]};
              } {
                return null;
              }
            }
            if ((type == "img_to_3d") && (model == "type1")) {
              if (response && response.data && response.data.output && response.data.output[0]) {
                return {outputType: "img", value: response.data.output[0]};
              } {
                return null;
              }
            }
            if ((type == "text_to_3d") && (model == "type1")) {
              if (response && response.data && response.data.output && response.data.output[0]) {
                return {outputType: "img", value: response.data.output[0]};
              } {
                return null;
              }
            }
            if ((type == "text2video") && (model == "type1")) {
              if (response && response.data && response.data.output && response.data.output[0]) {
                return {outputType: "video", value: response.data.output[0]};
              } {
                return null;
              }
            }
          } else if ((response.data.status == "starting") || (response.data.status == "processing")) {
            return {outputType: "refetch", value: response.data.fetch_result}
          } else {
            return null;
          }
        } else {
          return null
        }
      case "replicate":
        if ((response.data.status == "failed")) {
          let errorArr=["Something went wrong"]
          if (response.data.messege) {
            errorArr = [response.data.messege]
          }
          return {outputType: "error", value: errorArr};
        }
        if (response && response.data) {
          if (response.data.status == "succeeded") {
            if ((type == "text2video") && (model == "type1")) {
              if (response && response.data && response.data.output && response.data.output) {
                return {outputType: "img", value: response.data.output[0]};
              } {
                return null;
              }
            }
            if ((type == "image2text") && (model == "type1")) {
              if (response && response.data && response.data.output) {
                return {outputType: "text", value: response.data.output};
              } {
                return null;
              }
            }
            if ((type == "video2video") && (model == "type1")) {
              if (response && response.data && response.data.output) {
                return {outputType: "img", value: response.data.output};
              } {
                return null;
              }
            }
            if ((type == "video2video") && (model == "foreground")) {
              if (response && response.data && response.data.output) {
                return {outputType: "video", value: response.data.output};
              } {
                return null;
              }
            }
            if ((type == "frames_to_video") && (model == "type1")) {
              if (response && response.data && response.data.output) {
                return {outputType: "video", value: response.data.output};
              } {
                return null;
              }
            }
          } else if ((response.data.status == "starting") || (response.data.status == "processing")) {
            return {outputType: "refetch", value: response.data.urls.get}
          } else {
            return null;
          }
        } else {
          return null
        }
      case "googlepalm": 
        if ((type == "text2text") && (model == "type1")) {
          if (response && response.data && response.data.output && response.data.output[0] && response.data.output[0].candidates) {
            return {outputType: "text", value: response.data.output[0].candidates[0].output}
          } else {
            return null;
          }
        }
    
      default:
        break;
    }
}
