import axiosdefault from "axios";
import { generateOutput, getCookie } from "../utils/methods";

const axios = axiosdefault.create({
  baseURL: `${process.env.REACT_APP_APP_API}/app/api`,
});

const versions = {
  text2video: "00b1c7885c5f1d44b51bcb56c378abc8f141eeacf94c1e64998606515fe63a8d",
  video2video: "558a8a71c36e900af4a9aec372cc77ebd93dc668fa3d3244e689b9a971d158e1",
  image2text: "50adaf2d3ad20a6f911a8a9e3ccf777b263b8596fbd2c8fc26e8888f8a0edbb5",
  frames_to_video: "f55350b82ee3d07b0a2dd329df57dd97dedb5a08daddbdd6580d3cd473eaff1d",
}

const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file)
  const response = await axios.post(`/upload`, formData,{
    headers: {
      "Content-Type": "multipart/form-data",
      "auth-token": getCookie('token')
    }
  })
  return response.data
}

const generateModel = async (type, service_name, model_type, payload) => {
  try {
    let headers = {
      "Content-Type": "application/json",
      "auth-token": getCookie('token')
    }
    if (service_name == "replicate") {
      let version=""
      if ((type == "video2video") && (model_type == "foreground")) {
        version="73d2128a371922d5d1abf0712a1d974be0e4e2358cc1218e4e34714767232bac"
      }
      console.log("payloaad", version, type)
      payload = {
        version: version?version:versions[type],
        input: {
          ...payload,
        }
      }
    }
    const response = await axios.post(`/${type}/${service_name}${model_type?('/' + model_type):''}`, payload, {
        headers
    })
    console.log("generateOutput(response.data,", response.data)
    sessionStorage.setItem("outputToken", response.data.outputToken)
    return generateOutput(response.data, service_name, type, model_type);
    // return response.data.data;
  } catch (error) {
    console.log("AI error", error)
    if (error?.response?.data?.error) {
      return {error: error.response.data.error}
    }
    return {error: error.message}
  }
}

const getModelOutput = async (payload) => {
  try {
      const response = await axios.post('/get/output', {...payload, outputToken: sessionStorage.getItem("outputToken")}, {
        headers: {
          "auth-token": getCookie('token')
        }
      })
      return response.data;
  } catch (error) {
      // console.log("getModelOutput", error)
      if (error?.response?.data?.message) {
          return {error: error.response.data.message}
      }
      return {error: error.message}
  }
}

export { generateModel, uploadFile, getModelOutput };