import axios from "axios";
import { getCookie } from "../utils/methods";

//Use a global variable for the backend url for ngrok
//let backend_url = "https://db6e-2405-204-a507-d852-5464-aa2a-4326-84a8.ngrok-free.app"

const apiUrl = `${process.env.REACT_APP_APP_API}/auth/api`
//const apiUrl = `${backend_url}/auth/api`

console.log(apiUrl);

console.log("process.env.REACT_APP_APP_API", process.env.REACT_APP_APP_API)

const register = async (payload) => {
    try {
        const response = await axios.post(`${apiUrl}/register`, payload)
        return response.data;
    } catch (error) {
        console.log("AI error", error)
        if (error?.response?.data?.message) {
            return {error: error.response.data.message}
        }
        return {error: error.message}
    }
}

const login = async (payload) => {
    try {
        const response = await axios.post(`${apiUrl}/login`, payload, {
            headers: {
                'ngrok-skip-browser-warning':true
            }
        })
        return response.data;
    } catch (error) {
        console.log("AI error", error)
        if (error?.response?.data?.message) {
            return {error: error.response.data.message}
        }
        return {error: error.message}
    }
}

const requestPasswordChange = async (payload) => {
    try {
        const response = await axios.post(`${apiUrl}/request-password-change`, payload)
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            return {error: error.response.data.message}
        }
        return {error: error.message}
    }
}

const resetPassword = async (payload) => {
    try {
        const response = await axios.post(`${apiUrl}/change-password`, payload)
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            return {error: error.response.data.message}
        }
        return {error: error.message}
    }
}

const getCredits = async () => {
    try {
        const response = await axios.get(`${apiUrl}/available-credits`, {
            headers: {
                "auth-token": getCookie('token'), 
            }
        })
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            return {error: error.response.data.message}
        }
        return {error: error.message}
    }
}

export { register, login, getCredits, requestPasswordChange, resetPassword };