import TextArea from '../../components/common/TextArea'
import Select from '../../components/common/Select'
import NumberWithRange from '../../components/common/NumberWithRange';
import NumberInput from '../../components/common/NumberInput';
import AudioUpload from '../../components/common/AudioUpload';

export const chatgpt = {
    text2text: {
    type1: [
        {
            component: TextArea,
            props: {
                label: "prompt",
                defaultValue: "These practices, also known as ‘dark patterns,’ are in the nature of unfair trade practices and are covered under the Consumer Protection Act, which is punishable, according to Union consumer affairs secretary Rohit Kumar Singh.",
                name: "prompt",
                description: "Prompt to send to FLAN-T5.",
                style: {
                    height: "122px !important",
                }
            }
        },
        {
            component: NumberInput,
            props: {
                label: "max_tokens",
                defaultValue: "16",
                min: "0",
                max: "30",
                id: "input-max_tokens",
                name: "max_tokens",
                step: "1",
                description: "Random seed. Leave blank to randomize the seed",
            }
        },
        {
            component: NumberWithRange,
            props: {
                label: "temperature",
                defaultValue: "0.75",
                min: "0.01",
                max: "2",
                id: "input-temperature",
                name: "temperature",
                step: "0.01",
                description: "Adjusts randomness of outputs, greater than 1 is random and 0 is deterministic, 0.75 is a good starting value. (minimum: 0.01; maximum: 5)",
            }
        },
        {
            component: NumberWithRange,
            props: {
                label: "top_p",
                defaultValue: "1",
                min: "0.01",
                max: "1",
                name: "top_p",
                step: "0.01",
                description: "When decoding text, samples from the top p percentage of most likely tokens; lower to ignore less likely tokens (minimum: 0.01; maximum: 1)",
            }
        },
        {
            component: Select,
            props: {
                id: "input-echo",
                label: "echo",
                name: "echo",
                description: "Echo back the prompt in addition to the completion",
                options: [
                    { label: "True", value: true },
                    { label: "False", value: false },
                ],
            }
        }
    ],
    gpt4: [
        {
            component: TextArea,
            props: {
                label: "prompt",
                defaultValue: "These practices, also known as ‘dark patterns,’ are in the nature of unfair trade practices and are covered under the Consumer Protection Act, which is punishable, according to Union consumer affairs secretary Rohit Kumar Singh.",
                name: "prompt",
                description: "Prompt to send to FLAN-T5.",
                style: {
                    height: "122px !important",
                }
            }
        },
        {
            component: NumberInput,
            props: {
                label: "max_tokens",
                defaultValue: "16",
                min: "0",
                max: "30",
                id: "input-max_tokens",
                name: "max_tokens",
                step: "1",
                description: "Random seed. Leave blank to randomize the seed",
            }
        },
        {
            component: NumberWithRange,
            props: {
                label: "temperature",
                defaultValue: "0.75",
                min: "0.01",
                max: "2",
                id: "input-temperature",
                name: "temperature",
                step: "0.01",
                description: "Adjusts randomness of outputs, greater than 1 is random and 0 is deterministic, 0.75 is a good starting value. (minimum: 0.01; maximum: 5)",
            }
        },
        {
            component: NumberWithRange,
            props: {
                label: "top_p",
                defaultValue: "1",
                min: "0.01",
                max: "1",
                name: "top_p",
                step: "0.01",
                description: "When decoding text, samples from the top p percentage of most likely tokens; lower to ignore less likely tokens (minimum: 0.01; maximum: 1)",
            }
        }
    ]},
    text2img: {type1: [
        {
            component: TextArea,
            props: {
                label: "prompt",
                defaultValue: "an astronaut riding a horse on mars, hd, dramatic lighting",
                name: "prompt",
                description: "Input prompt",
                style: {
                    height: "50px !important",
                }
            }
        },
        {
            component: Select,
            props: {
                id: "input-size",
                label: "size",
                name: "size",
                description: "Generated images can have a size of 256x256, 512x512, or 1024x1024 pixels. Smaller sizes are faster to generate. You can request 1-10 images at a time using the n parameter.",
                options: [{
                    label: "256x256",
                    value: "256x256",
                },{
                    label: "512x512",
                    value: "512x512",
                },{
                    label: "1024x1024",
                    value: "1024x1024",
                },],
            }
        },
    ]},
    speech2text: {type1: [
        {
            component: AudioUpload,
            props: {
                label: "audio_file",
                id: "input-audio_file",
                name: "audio_file",
                description: "The main input audio",
                isAudioInput: true
            }
        },
    ]}
}