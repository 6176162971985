import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Input from '../components/common/Input';
import { login, register } from '../services/auth';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import Alert from '../components/Alert';

const Login = ({setAuth}) => {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({})
    const [errorMsg, setErrorMsg] = useState("")

    const handleChange = e => {
            setCredentials(prevCreds=>{
                prevCreds[e.target.name] = e.target.value;
                return prevCreds;
            })
    }
    const handleSubmit = async (e, user) => {
        try {
            const response = await login(user?user:credentials);
            if (response && response.error) {
                setErrorMsg(response.error)
                return;
            } else {
                setErrorMsg("")
            }
            console.log("register", response.token)
            if (response.success) {
                document.cookie = "token=" + response.token;
                setAuth(true)
                navigate("/collections")
            }
        } catch (error) {
            console.log("error", error)
        }
    }
    return (
        <div className='flex items-center justify-center bg-black w-full h-screen'>
            {
                errorMsg &&
                <Alert message={errorMsg} />
            }
            <div className='p-10 bg-white flex flex-col' style={{ width: "500px" }}>
                <h3 className='uppercase'>LOGIN</h3>
                {/* <div className='flex flex-col py-3'>
                    <label htmlFor="" className='font-semibold text-slate-500'>User Name</label>
                    <input type="text" placeholder='Enter Login Id' className='p-3 mt-2 border border-2 border-solid border-black' />
                </div> */}
                <Input name="username" label="Username" type="text" placeholder='Enter Username' onChange={handleChange}  />
                <Input name="password" label="Password" type="password" placeholder='Enter Password' onChange={handleChange} />
                {/* <div className='flex flex-col py-3'>
                    <label htmlFor="" className='font-semibold text-slate-500'>Password</label>
                    <input type="password" placeholder='Enter Password' className='p-3 mt-2 border border-2 border-solid border-black' />
                </div> */}
                <div className="flex items-stretch justify-between my-4">
                    <button className='bg-black text-white px-8 uppercase' onClick={handleSubmit}>LOGIN</button>
                    {/* <div role='button' className='h-12 px-2 uppercase flex items-center border border-black justify-between '><img src={require("../assets/Images/google-login.png")} alt="" width="40px" height="auto"/><span className='font-bold'>Sign in with Google</span></div> */}
                    <GoogleOAuthProvider>
                        <GoogleLogin
                            client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            scope={"profile email"}
                            onSuccess={async (response) => {
                                // Handle the successful Google login
                                console.log("google login", response)
                                var tokens = response.credential.split(".");
                                console.log(JSON.parse(atob(tokens[0])));
                                console.log(JSON.parse(atob(tokens[1])));
                                const user = JSON.parse(atob(tokens[1]))
                                const newUser = {
                                    username: user.email,
                                    email: user.email,
                                    password: JSON.parse(atob(tokens[0])).kid
                                }
                                const res = await register(newUser)
                                if (res.success) {
                                    // navigate("/login")
                                    handleSubmit(null, newUser)
                                }
                                console.log("res.error", res)
                                if (res.error && ((res.error == "Username already exists") || (res.error == "Email already exists"))) {
                                    handleSubmit(null, newUser)
                                }
                            }}
                            onFailure={(error) => {
                                // Handle the failed Google login
                            }}
                        />
                    </GoogleOAuthProvider>
                   
                </div>
                <div className="flex items-center justify-between">
                    <a href="/#" className='text-slate-500' onClick={(e) => {
                            e.preventDefault();
                            navigate("/change-password")
                        }}>Forgot Password?</a>
                    <a href="/#" className='text-slate-500' onClick={(e) => {
                            e.preventDefault();
                            navigate("/register")
                        }}>Create a New Account</a>
                </div>
            </div>
        </div>
    )
}

export default Login