import React from 'react'

function ImageUpload({ label, description, isVideo, ...inputProps }) {
    return (
        <div className="mb-lh">
            <label className="block mb-2" htmlFor="input-reference_image">
                <svg
                    className="inline-block mr-2 w-3 h-3"
                    fill="none"
                    height="24"
                    role="presentation"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect height="18" rx="2" ry="2" width="18" x="3" y="3" />
                    <circle cx="8.5" cy="8.5" r="1.5" />
                    <polyline points="21 15 16 10 5 21" />
                </svg>
                <code>{label}</code>
            </label>
            <div className="text-shade border-black border-2 border-opacity-10 border-dashed px-lh py-05lh cursor-pointer relative">
                <input style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", zIndex: "10", opacity: "0", cursor: "pointer" }} type="file" {...inputProps} />
                <div>
                    <svg
                        className="icon"
                        fill="none"
                        height="24"
                        role="presentation"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                        <polyline points="17 8 12 3 7 8" />
                        <line x1="12" x2="12" y1="3" y2="15" />
                    </svg>{" "}
                    Drop a file or click to select
                    <div className="flex ">
                        <div className="text-sm truncate pt-1">
                            https://replicate.delivery/mgxm/f51f0b0f-2710-4aba-ac62-e74857f519bd/wood2_fliped.jpeg
                        </div>
                        <button
                            className="flex-shrink pointer-events-auto hover:bg-black hover:text-white p-1 ml-1"
                            title="Clear"
                            type="button"
                        >
                            <svg
                                className="icon"
                                fill="none"
                                height="24"
                                role="presentation"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <polyline points="3 6 5 6 21 6" />
                                <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
                                <line x1="10" x2="10" y1="11" y2="17" />
                                <line x1="14" x2="14" y1="11" y2="17" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <button
                    className="text-shade border-black border-2 border-dashed px-lh py-05lh cursor-pointer border-opacity-10 w-full text-left"
                    type="button"
                >
                    <svg
                        className="icon"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M14.5 4h-5L7 7H4a2 2 0 00-2 2v9a2 2 0 002 2h16a2 2 0 002-2V9a2 2 0 00-2-2h-3l-2.5-3z" />
                        <circle cx="12" cy="13" r="3" />
                    </svg>{" "}
                    Take a photo with your webcam
                </button>
            </div>
            <p className="text-shade mt-1">
                {description}
            </p>
            <div className="mt-lh pb-lh">
                {
                    isVideo ?
                        <div
                            className="w-full"
                            style={{
                                height: "auto",
                                width: "auto",
                            }}
                        >
                            <video
                                __idm_id__="1327105"
                                autoPlay
                                controls
                                loop
                                preload="auto"
                                src={inputProps.previews ? inputProps.previews[inputProps.name] : ""}
                                style={{
                                    height: "auto",
                                    width: "auto",
                                }}
                            />
                        </div>
                        :
                        <a
                            to="#"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img
                                alt="https://replicate.delivery/mgxm/f51f0b0f-2710-4aba-ac62-e74857f519bd/wood2_fliped.jpeg"
                                className="lazy"
                                src={inputProps.previews ? inputProps.previews[inputProps.name] : ""}
                            />
                        </a>
                }
            </div>
        </div>
    )
}

export default ImageUpload