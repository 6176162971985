import React from 'react'

function Input({label, ...inputProps}) {
  return (
    <div className="mb-lh">
          <label className="block mb-2" htmlFor="input-prompt">
            {label}
          </label>
          <div>
            <input
              className="form-input w-full resize-none"
              {...inputProps}
            />
          </div>
        </div>
  )
}

export default Input