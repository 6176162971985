import { useNavigate } from "react-router-dom/dist";

const ModelHeader = () => {
    const navigate = useNavigate();
    return (
        <>
            <div class=" text-center py-3 px-6 bg-brand text-black hidden md:block mb-6">
                🚀 Want to run this model with an API?
                <a class="bubble ml-3" href="">
                    Get started
                </a>
            </div>
            <header className="flex flex-col md:flex-row md:items-end gap-lh mb-2lh">
                <hgroup className="flex-grow flex flex-col gap-05lh">
                    <div className="flex">
                        <div>
                            <h3>
                                <span className="text-shade">
                                    <a href="/stability-ai">stability-ai</a>
                                </span>

                                <span className="text-shade px-1">/</span>

                                <span className="block md:inline">stable-diffusion</span>
                            </h3>
                        </div>

                        <div className="flex-shrink-0 ml-3 flex items-center">
                            <script
                                dangerouslySetInnerHTML={{
                                    __html:
                                        '{"content": "stability-ai/stable-diffusion", "label": "Copy model name"}',
                                }}
                                id="react-component-props-9219145c-e255-4da2-ae39-521411fa0adb"
                                type="application/json"
                            />

                            <div
                                data-component="CopyIconButton"
                                data-props="react-component-props-9219145c-e255-4da2-ae39-521411fa0adb"
                            >
                                <button
                                    className="w-7 h-7 inline-flex items-center justify-center relative hover:bg-gray-100"
                                    data-state="closed"
                                >
                                    <span className="sr-only">Copy model name</span>
                                    <svg
                                        className="pointer-events-none text-shade"
                                        fill="none"
                                        height="16"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        width="16"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect height="13" rx="2" ry="2" width="13" x="9" y="9" />
                                        <path d="M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <p>
                        <span className="mr-2">
                            <span className="inline-block rounded-full text-black bg-bgshade small-caps px-3 pb-1">
                                <span
                                    className="relative"
                                    style={{
                                        top: "0.1em",
                                    }}
                                >
                                    <svg
                                        className="icon"
                                        fill="none"
                                        height="24"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx="12" cy="12" r="10" />

                                        <line x1="2" x2="22" y1="12" y2="12" />

                                        <path d="M12 2a15.3 15.3 0 014 10 15.3 15.3 0 01-4 10 15.3 15.3 0 01-4-10 15.3 15.3 0 014-10z" />
                                    </svg>
                                </span>
                                Public
                            </span>
                        </span>
                        A latent text-to-image diffusion model capable of generating
                        photo-realistic images given any text input
                    </p>

                    <ul className="flex gap-lh">
                        <li>
                            <svg
                                className="icon"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20.322.75a10.75 10.75 0 00-7.373 2.926l-1.304 1.23A23.743 23.743 0 0010.103 6.5H5.066a1.75 1.75 0 00-1.5.85l-2.71 4.514a.75.75 0 00.49 1.12l4.571.963c.039.049.082.096.129.14L8.04 15.96l1.872 1.994c.044.047.091.09.14.129l.963 4.572a.75.75 0 001.12.488l4.514-2.709a1.75 1.75 0 00.85-1.5v-5.038a23.741 23.741 0 001.596-1.542l1.228-1.304a10.75 10.75 0 002.925-7.374V2.499A1.75 1.75 0 0021.498.75h-1.177zM16 15.112c-.333.248-.672.487-1.018.718l-3.393 2.262.678 3.223 3.612-2.167a.25.25 0 00.121-.214v-3.822zm-10.092-2.7L8.17 9.017c.23-.346.47-.685.717-1.017H5.066a.25.25 0 00-.214.121l-2.167 3.612 3.223.679zm8.07-7.644a9.25 9.25 0 016.344-2.518h1.177a.25.25 0 01.25.25v1.176a9.25 9.25 0 01-2.517 6.346l-1.228 1.303a22.248 22.248 0 01-3.854 3.257l-3.288 2.192-1.743-1.858a.764.764 0 00-.034-.034l-1.859-1.744 2.193-3.29a22.248 22.248 0 013.255-3.851l1.304-1.23zM17.5 8a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-11 13c.9-.9.9-2.6 0-3.5-.9-.9-2.6-.9-3.5 0-1.209 1.209-1.445 3.901-1.49 4.743a.232.232 0 00.247.247c.842-.045 3.534-.281 4.743-1.49z"
                                    fill="currentColor"
                                    fillRule="evenodd"
                                />
                            </svg>
                            97.3M runs
                        </li>
                    </ul>
                </hgroup>
            </header>
            <div className="tabs flex gap-2 md:gap-2lh mb-lh md:mb-2lh border-b border-hairline">
                <a
                    className="tab"
                    onClick={(e) => {
                        e.preventDefault()
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <svg
                        className="icon"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <polygon points="5 3 19 12 5 21 5 3" />
                    </svg>
                    Demo
                </a>

                <a
                    className="tab"
                    onClick={(e) => {
                        e.preventDefault()
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <svg
                        className="icon"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20.322.75a10.75 10.75 0 00-7.373 2.926l-1.304 1.23A23.743 23.743 0 0010.103 6.5H5.066a1.75 1.75 0 00-1.5.85l-2.71 4.514a.75.75 0 00.49 1.12l4.571.963c.039.049.082.096.129.14L8.04 15.96l1.872 1.994c.044.047.091.09.14.129l.963 4.572a.75.75 0 001.12.488l4.514-2.709a1.75 1.75 0 00.85-1.5v-5.038a23.741 23.741 0 001.596-1.542l1.228-1.304a10.75 10.75 0 002.925-7.374V2.499A1.75 1.75 0 0021.498.75h-1.177zM16 15.112c-.333.248-.672.487-1.018.718l-3.393 2.262.678 3.223 3.612-2.167a.25.25 0 00.121-.214v-3.822zm-10.092-2.7L8.17 9.017c.23-.346.47-.685.717-1.017H5.066a.25.25 0 00-.214.121l-2.167 3.612 3.223.679zm8.07-7.644a9.25 9.25 0 016.344-2.518h1.177a.25.25 0 01.25.25v1.176a9.25 9.25 0 01-2.517 6.346l-1.228 1.303a22.248 22.248 0 01-3.854 3.257l-3.288 2.192-1.743-1.858a.764.764 0 00-.034-.034l-1.859-1.744 2.193-3.29a22.248 22.248 0 013.255-3.851l1.304-1.23zM17.5 8a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-11 13c.9-.9.9-2.6 0-3.5-.9-.9-2.6-.9-3.5 0-1.209 1.209-1.445 3.901-1.49 4.743a.232.232 0 00.247.247c.842-.045 3.534-.281 4.743-1.49z"
                            fill="currentColor"
                            fillRule="evenodd"
                        />
                    </svg>
                    API
                </a>

                <a
                    className="tab"
                    onClick={(e) => {
                        e.preventDefault()
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <svg
                        className="icon"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect height="18" rx="2" ry="2" width="18" x="3" y="3" />

                        <polyline points="11 3 11 11 14 8 17 11 17 3" />
                    </svg>
                    Examples
                </a>
            </div>
        </>
    );
};

export default ModelHeader;
