import React from 'react'

function VideoOutput({output}) {
    return (
        <div>
            <div
                className="w-full"
                style={{
                    height: "auto",
                    width: "auto",
                }}
            >
                <video
                    __idm_id__="1572865"
                    autoPlay
                    controls
                    loop
                    preload="auto"
                    src={output}
                    style={{
                        height: "auto",
                        width: "auto",
                    }}
                />
            </div>
        </div>
    )
}

export default VideoOutput