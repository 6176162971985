import React, { useState } from 'react'
import Input from './common/Input'
import { getCookie } from '../utils/methods'

const Upgrade = ({message}) => {
    const [quantity, setQuantity] = useState(1)
    return (
        <>
            <div className='h-screen w-full flex justify-center items-center' style={{ background: "grey", backgroundPosition: "center", backgroundSize: "cover" }}>
                <div className='w-full max-w-lg p-5 text-center bg-white flex align-center justify-center flex-col'>
                    <h3 className='font-bold'>{message?message:"Your Free Credits Have Ended"}</h3>
                    {
                        !message?
                        <p className='my-5'>But it’s not too late to take the next step. Upgrade your plan to keep using features.</p>
                        :null
                    }
                    <form action={`${process.env.REACT_APP_APP_API}/app/api/process-payment`} method="POST">
                        <input type="hidden" name="token" value={getCookie('token')} />
                        <Input style={{width: "auto", margin: "auto"}} type="number" min="1" name="quantity" value={quantity} onChange={e=> setQuantity(e.target.value)} />
                        <button type="submit" className='bg-black text-white px-8 h-11 uppercase'>Upgrade Now</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Upgrade