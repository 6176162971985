import TextArea from '../../components/common/TextArea'
import Select from '../../components/common/Select'
import NumberWithRange from '../../components/common/NumberWithRange';
import NumberInput from '../../components/common/NumberInput';
import ImageUpload from '../../components/common/ImageUpload';

const getHeightOptions = () => {
    const opts = [{
        label: "",
        value: "",
    }];
    for (let i = 1; i < 17; i++) {
        opts[i] = {
            label: i * 64,
            value: i * 64,
        }
    }
    return opts
}

export const stable_fusion = {
    text2img: {
        type1: [
        {
            component: TextArea,
            props: {
                label: "prompt",
                defaultValue: "an astronaut riding a horse on mars, hd, dramatic lighting",
                name: "prompt",
                description: "Input prompt",
                style: {
                    height: "50px !important",
                }
            }
        },
        {
            component: Select,
            props: {
                id: "input-height",
                label: "height",
                name: "height",
                description: "Height of generated image in pixels. Needs to be a multiple of 64",
                options: getHeightOptions(),
            }
        },
        {
            component: Select,
            props: {
                id: "input-width",
                label: "width",
                name: "width",
                description: "Width of generated image in pixels. Needs to be a multiple of 64",
                options: getHeightOptions(),
            }
        },
        {
            component: TextArea,
            props: {
                label: "negative_prompt",
                name: "negative_prompt",
                description: "Specify things to not see in the output",
                style: {
                    height: "50px !important",
                }
            }
        },
        {
            component: NumberWithRange,
            props: {
                label: "num_outputs",
                defaultValue: "1",
                max: "4",
                min: "1",
                name: "num_outputs",
                step: "1",
            }
        },
        {
            component: NumberWithRange,
            props: {
                label: "num_inference_steps",
                defaultValue: "50",
                max: "500",
                min: "1",
                name: "num_inference_steps",
                step: "1",
            }
        },
        {
            component: NumberWithRange,
            props: {
                label: "guidance_scale",
                defaultValue: "7.5",
                max: "20",
                min: "1",
                name: "guidance_scale",
                step: "0.01",
            }
        },
        {
            component: Select,
            props: {
                id: "input-scheduler",
                label: "scheduler",
                name: "scheduler",
                description: "Choose a scheduler.",
                options: [{
                    label: "",
                    value: ""
                },
                {
                    label: "DDIM",
                    value: "DDIM"
                },
                {
                    label: "K_EULER",
                    value: "K_EULER"
                },
                {
                    label: "DPMSolverMultistep",
                    value: "DPMSolverMultistep"
                },
                {
                    label: "K_EULER_ANCESTRAL",
                    value: "K_EULER_ANCESTRAL"
                },
                {
                    label: "PNDM",
                    value: "PNDM"
                },
                {
                    label: "KLMS",
                    value: "KLMS"
                },],
            }
        },
        {
            component: NumberInput,
            props: {
                label: "seed",
                defaultValue: "",
                id: "input-seed",
                name: "seed",
                step: "1",
                description: "Random seed. Leave blank to randomize the seed",
            }
        },
    ]},
    img2img: {
        type1: [
        {
            component: TextArea,
            props: {
                label: "prompt",
                defaultValue: "A path going into the woods",
                name: "prompt",
                description: "Prompt",
                style: {
                    height: "50px !important",
                }
            }
        },
        {
            component: TextArea,
            props: {
                label: "negative_prompt",
                name: "negative_prompt",
                description: "Specify things to not see in the output",
                style: {
                    height: "50px !important",
                }
            }
        },
        // {
        //     component: ImageUpload,
        //     props: {
        //         label: "init_image",
        //         id: "input-init_image",
        //         name: "init_image",
        //         description: "The main input image - Style image in style-transfer.",
        //         isImageInput: true
        //     }
        // },
        {
            component: ImageUpload,
            props: {
                label: "init_image",
                name: "init_image",
                description: "The main input image - Style image in style-transfer.",
                isImageInput: true
            }
        },
        {
            component: Select,
            props: {
                id: "input-height",
                label: "height",
                name: "height",
                defaultValue: "192",

                description: "Height of generated image in pixels. Needs to be a multiple of 64",
                options: getHeightOptions(),
            }
        },
        {
            component: Select,
            props: {
                id: "input-width",
                label: "width",
                name: "width",
                defaultValue: "192",

                description: "Width of generated image in pixels. Needs to be a multiple of 64",
                options: getHeightOptions(),
            }
        },
        {
            component: NumberInput,
            props: {
                label: "samples",
                id: "input-samples",
                defaultValue: "1",
                name: "samples",
                max: "4",
                step: "1",
                description: "Number of ramdom projections for SWD. More is better results but is slower and memory inefficient",
            }
        },
        {
            component: NumberInput,
            props: {
                label: "strength",
                id: "input-strength",
                name: "strength",
                max: "1.0",
                min: "0.0",
                step: "0.1",
                description: "Number of ramdom projections for SWD. More is better results but is slower and memory inefficient",
            }
        },
        {
            component: Select,
            props: {
                id: "input-num_inference_steps",
                label: "num_inference_steps",
                name: "num_inference_steps",
                description: "Number of denoising steps. Available values: 21, 31, 41, 51.",
                options: [{
                    label: "21",
                    value: "21"
                },{
                    label: "31",
                    value: "31"
                },{
                    label: "41",
                    value: "41"
                },{
                    label: "51",
                    value: "51"
                },],
            }
        },
        {
            component: Select,
            props: {
                id: "input-enhance_prompt",
                label: "enhance_prompt",
                name: "enhance_prompt",
                description: "Enhance prompts for better results; default: yes, options: yes/no",
                options: [{
                    label: "yes",
                    value: "yes"
                },{
                    label: "no",
                    value: "no"
                }],
            }
        },
        {
            component: NumberWithRange,
            props: {
                label: "guidance_scale",
                defaultValue: "7.5",
                max: "20",
                min: "1",
                name: "guidance_scale",
                step: "0.01",
            }
        },
        // {
        //     component: NumberInput,
        //     props: {
        //         label: "patch_size",
        //         defaultValue: "8",
        //         id: "input-patch_size",
        //         name: "patch_size",
        //         step: "1",
        //         description: "Size of the extracted a patches",
        //     }
        // },
        // {
        //     component: NumberInput,
        //     props: {
        //         label: "height_factor",
        //         defaultValue: "1",
        //         id: "input-height_factor",
        //         name: "height_factor",
        //         step: "1",
        //         description: "Change the output's aspect ratio (Retargeting)",
        //     }
        // },
        // {
        //     component: NumberInput,
        //     props: {
        //         label: "width_factor",
        //         defaultValue: "1",
        //         id: "input-width_factor",
        //         name: "width_factor",
        //         step: "1",
        //         description: "Change the output's aspect ratio (Retargeting)",
        //     }
        // },
    ]}
    ,
    img_to_3d: {
        type1: [
            {
                component: ImageUpload,
                props: {
                    label: "image",
                    name: "image",
                    description: "Input Image",
                    isImageInput: true
                }
            },
            {
                component: NumberWithRange,
                props: {
                    label: "steps",
                    defaultValue: "10",
                    max: "50",
                    min: "1",
                    name: "steps",
                    step: "1",
                }
            },
            {
                component: NumberWithRange,
                props: {
                    label: "guidance_scale",
                    defaultValue: "7.5",
                    max: "20",
                    min: "1",
                    name: "guidance_scale",
                    step: "0.01",
                }
            },
            {
                component: Select,
                props: {
                    id: "input-frame_size",
                    label: "frame_size",
                    name: "frame_size",
                    description: "Frame Size of generated image in pixels. Needs to be a multiple of 64",
                    options: getHeightOptions(),
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "seed",
                    defaultValue: "",
                    id: "input-seed",
                    name: "seed",
                    step: "1",
                    description: "Random seed. Leave blank to randomize the seed",
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "seconds",
                    defaultValue: "",
                    id: "input-seconds",
                    name: "seconds",
                    step: "1",
                    description: "Number of seconds",
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "batch_size",
                    id: "input-batch_size",
                    name: "batch_size",
                    step: "1",
                    description: "Number of images per prompt",
                }
            },
            {
                component: Select,
                props: {
                    id: "input-safety_checker",
                    label: "safety_checker",
                    name: "safety_checker",
                    description: "A checker for NSFW images. If such an image is detected, it will be replaced by a blank image.",
                    options: [{
                        label: "yes",
                        value: "yes"
                    },
                    {
                        label: "no",
                        value: "no"
                    }],
                }
            },
            {
                component: Select,
                props: {
                    id: "input-output_type",
                    label: "output_type",
                    name: "output_type",
                    description: "Choose a Output Type.",
                    options: [{
                        label: "gif",
                        value: "gif"
                    },
                    {
                        label: "ply",
                        value: "ply"
                    }],
                }
            },
        ]
    },
    text_to_3d: {
        type1: [
            {
                component: TextArea,
                props: {
                    label: "prompt",
                    defaultValue: "an astronaut riding a horse on mars, hd, dramatic lighting",
                    name: "prompt",
                    description: "Input prompt",
                    style: {
                        height: "50px !important",
                    }
                }
            },
            {
                component: TextArea,
                props: {
                    label: "negative_prompt",
                    name: "negative_prompt",
                    description: "Specify things to not see in the output",
                    style: {
                        height: "50px !important",
                    }
                }
            },
            {
                component: NumberWithRange,
                props: {
                    label: "steps",
                    defaultValue: "10",
                    max: "50",
                    min: "1",
                    name: "steps",
                    step: "1",
                }
            },
            {
                component: NumberWithRange,
                props: {
                    label: "guidance_scale",
                    defaultValue: "7.5",
                    max: "20",
                    min: "1",
                    name: "guidance_scale",
                    step: "0.01",
                }
            },
            {
                component: Select,
                props: {
                    id: "input-frame_size",
                    defaultValue: 256,
                    label: "frame_size",
                    name: "frame_size",
                    description: "Frame Size of generated image in pixels. Needs to be a multiple of 64",
                    options: getHeightOptions(),
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "seed",
                    defaultValue: "",
                    id: "input-seed",
                    name: "seed",
                    step: "1",
                    description: "Random seed. Leave blank to randomize the seed",
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "seconds",
                    defaultValue: "",
                    id: "input-seconds",
                    name: "seconds",
                    step: "1",
                    description: "Number of seconds",
                }
            },
            {
                component: Select,
                props: {
                    id: "input-output_type",
                    label: "output_type",
                    name: "output_type",
                    defaultValue: "gif",
                    description: "Choose a Output Type.",
                    options: [{
                        label: "gif",
                        value: "gif"
                    },
                    {
                        label: "ply",
                        value: "ply"
                    }],
                }
            },
        ],
    },
    text2video: {
        type1: [
            {
                component: TextArea,
                props: {
                    label: "prompt",
                    defaultValue: "A path going into the woods",
                    name: "prompt",
                    description: "Prompt",
                    style: {
                        height: "50px !important",
                    }
                }
            },
            {
                component: TextArea,
                props: {
                    label: "negative_prompt",
                    name: "negative_prompt",
                    description: "Items you don't want in the video.",
                    style: {
                        height: "50px !important",
                    }
                }
            },
            {
                component: Select,
                props: {
                    id: "input-scheduler",
                    label: "scheduler",
                    name: "scheduler",
                    description: "Use it to set a scheduler for video creation.",
                    options: [{
                        label: "DDPMScheduler",
                        value: "DDPMScheduler"
                    },
                    {
                        label: "DDIMScheduler",
                        value: "DDIMScheduler"
                    },
                    {
                        label: "PNDMScheduler",
                        value: "PNDMScheduler"
                    },
                    {
                        label: "LMSDiscreteScheduler",
                        value: "LMSDiscreteScheduler"
                    },
                    {
                        label: "EulerDiscreteScheduler",
                        value: "EulerDiscreteScheduler"
                    },
                    {
                        label: "EulerAncestralDiscreteScheduler",
                        value: "EulerAncestralDiscreteScheduler"
                    },
                    {
                        label: "DPMSolverMultistepScheduler",
                        value: "DPMSolverMultistepScheduler"
                    },
                    {
                        label: "HeunDiscreteScheduler",
                        value: "HeunDiscreteScheduler"
                    },
                    {
                        label: "KDPM2DiscreteScheduler",
                        value: "KDPM2DiscreteScheduler"
                    },
                    {
                        label: "DPMSolverSinglestepScheduler",
                        value: "DPMSolverSinglestepScheduler"
                    },
                    {
                        label: "KDPM2AncestralDiscreteScheduler",
                        value: "KDPM2AncestralDiscreteScheduler"
                    },
                    {
                        label: "UniPCMultistepScheduler",
                        value: "UniPCMultistepScheduler"
                    },
                    {
                        label: "DDIMInverseScheduler",
                        value: "DDIMInverseScheduler"
                    },
                    {
                        label: "DEISMultistepScheduler",
                        value: "DEISMultistepScheduler"
                    },
                    {
                        label: "IPNDMScheduler",
                        value: "IPNDMScheduler"
                    },
                    {
                        label: "KarrasVeScheduler",
                        value: "KarrasVeScheduler"
                    },
                    {
                        label: "ScoreSdeVeScheduler",
                        value: "ScoreSdeVeScheduler"
                    }],
                }
            },
            {
                component: NumberInput,
                props: {
                    label: "seconds",
                    id: "input-seconds",
                    name: "seconds",
                    step: "1",
                    min: "0",
                    max: "60",
                    description: "Number of iterations of pasting the image in it's center and inpainting the boarders",
                }
            },
        ]
    }
}