import React from "react";
import { Link } from "react-router-dom";

const CollectionCard = ({path, title, description, type, image}) => {
  return (
    <>
      <div className="inline-grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="h-40">
          <Link
            to={`/collection/${type}`}
            className="no-default"
            title="Text to Text"
          >
            <img
              alt=""
              className="object-cover object-center w-full h-full block align-middle lazy entered loaded"
              src={image}
            />
          </Link>
        </div>
        <div className="overflow-hidden break-words">
          <h4 className="whitespace-nowrap text-h4 overflow-hidden overflow-ellipsis">
            <Link to={`/collection/${type}`} className="no-default text-2xl">
              {title}
            </Link>
          </h4>
          <p className="mb-1 m-0">
            {description}
          </p>
          <p className="mb-1  text-[#00000073] text-sm"></p>
        </div>
      </div>
    </>
  );
};

export default CollectionCard;