export const collections = [
    {
        title: "Text-to-Text",
        description: "5 model fine tuned on GPT-3.5 generated paraphrase corpus of 6.3 million unique sentences.",
        type: "text2text",
        image: require("../assets/Images/card/textToText.png")
    },
    {
        title: "Text-to-Video",
        description: "Use Runway's Stable-diffusion inpainting model to create an infinite loop video",
        type: "text2video",
        image: require("../assets/Images/card/textToVideo.gif")
    },
    {
        title: "Text-to-Image",
        description: "A latent text-to-image diffusion model capable of generating photo-realistic images given any text input",
        type: "text2img",
        image: require("../assets/Images/card/textToImage.png")
    },
    {
        title: "Text-to-3D",
        description: "Text-to-3D generation",
        type: "text_to_3d",
        image: require("../assets/Images/card/TextToSpeech.png") //need image
    },
    {
        title: "Image-to-Image",
        description: "Generating Natural Images with Direct Patch Distribution Matching",
        type: "img2img",
        image: require("../assets/Images/card/ImageToImage.png")
    },
    {
        title: "Image-to-Text",
        description: "image to text generation",
        type: "image2text",
        image: require("../assets/Images/card/ImageToImage.png")
    },
    {
        title: "Image-to-3D",
        description: "Convert 3D GIF",
        type: "img_to_3d",
        image: require("../assets/Images/card/ImageToVideo.png") //need image
    },
    {
        title: "Speech-To-Text",
        description: "Convert speech in audio to text",
        type: "speech2text",
        image: require("../assets/Images/card/SpeechToText.png")
    },
    {
        title: "Video-to-video",
        description: "About Tune-A-Video:Tuning of Image Diffusion Models for Video-to-Video Generation",
        type: "video2video",
        image: require("../assets/Images/card/video-to-video-collect.png")
    },
    {
        title: "Frames-to-video",
        description: "Convert speech in audio to text",
        type: "frames_to_video",
        image: require("../assets/Images/card/video-to-video-collect.png")
    },
]